import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import data from "../../../assets/data/admin_list";
import Datatable from "../../common/datatable";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Row, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter } from "reactstrap";
import { Search } from "react-feather";
import jsCookie from 'js-cookie';
import axios from "axios";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { CSVLink, CSVDownload } from "react-csv";
const Orderview = () => {
	jsCookie.set('pagename', 'orderview');
	const [open, setOpen] = useState(false);
	let chartData = [];
	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};

	const [orderslist, setUsers] = useState([]);
	const [userarry, setArrayy] = useState([]);
	const [q, setQ] = useState("");
	const [find, setfind] = useState("0");
	//	const [ninjas, setUsers1] = useState([]);
	useEffect(() => {
		const loadUserslov = async () => {
			const response = await axios.get("https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=lob_createorder&viewtype=list");
			console.log('order',response.data); 
			setUsers(response.data);

		}

		loadUserslov();
	}, [])
	//console.log('ninjas', ninjas);
	function setarrayvalues(getarr) {
		setArrayy(getarr);
	}
	function Clear(val) {
		setQ(val);
		setArrayy([]);
		setfind('0');
	}

	function search() {

		let matches = [];
		//console.log(ninjas.filter((row) => row.email.toLowerCase().indexOf(q) > -1));
		matches = orderslist.filter(
			(row) =>
			(row.email.toLowerCase().indexOf(q) > -  1 || row.state.toLowerCase().indexOf(q) > -1|| row.city.toLowerCase().indexOf(q) > -1 || row.fullname.toLowerCase().indexOf(q) > -1|| row.companyname.indexOf(q) > -1
			));
		console.log('matches', matches);
		setarrayvalues(matches);

		if (matches == '') {
			setfind('1');
		}

	}
	const Getprofileimg = (a) => {
		const proimg = base64_decode('aHR0cHM6Ly9mYWNlcG9zdXBkYXRlZGJ1Y2tldG5ldzEyMzQ1NjE1Mzc1OC1kZXYuczMuZXUtd2VzdC0xLmFtYXpvbmF3cy5jb20vcHVibGljL3Byb2ZpbGUvTE9HSU1BR0UwLjMyMjE4NzA2MzM4OTY3NjM0LmpwZw==')
		return proimg;
	}
	const gourl = ()=>{
		window.location.href="/customeradd"
	}
	if (orderslist.length > 0) {

		return (

			<Fragment>
				<Breadcrumb title="Customer List" link="/customer" parent="Customer List"  />
				{/* <!-- Container-fluid starts--> */}
				<Container fluid={true}>
					<Row>

						<Col sm="12">
							<Card>
								
									<Row>
										<Col sm="6" style={{ padding: '20px' }}>

											<Button onClick={gourl}>Add</Button>
										</Col>
										
									</Row>

								<CardBody>
									


								
										{orderslist.length > 0 ?
										
										
									
											<Datatable
												myData={orderslist}
												pageSize={10}
												pagination={true}
												//className="-striped -highlight"
												sortable={true}											
												page="orderlist"
												
											/>
											
											: 
										<div className="row col-sm-12 "><h4 className="form-control text-center">Load......</h4></div>
									}


								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
				{/* <!-- Container-fluid Ends--> */}
			</Fragment>
		);
	}
	else
		return (<>Loading..</>);

};
export default Orderview;
