import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Row, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter } from "reactstrap";
import Breadcrumb from "../../common/breadcrumb";
import { useParams } from "react-router-dom";
import axios from "axios";
import jsCookie from 'js-cookie';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import {
    Badge,

} from "reactstrap";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
const Listview = () => {
    jsCookie.set('pagename', 'customer');
    const { cusid } = useParams();
    console.log(cusid);
    const [Users, setUsers] = useState([]);
    const [subUsers, setsubUsers] = useState([]);
    useEffect(() => {
        const loadsubusers = async () => {
            const response = await axios.get("https://www.laabamone.com/appoint_api.php?eventtype=loginregistercompany_user&compid=" + cusid);
            console.log('loc', response.data);
            setsubUsers(response.data);
            // setBanks(response.data[0].selectedbanks);

        }
        const loadUserslov = async () => {
            const response = await axios.get("https://www.laabamone.com/appoint_api.php?eventtype=getregistercompanydetails&id=" + cusid);
            // console.log('loc', response.data);
            setUsers(response.data);
            // setBanks(response.data[0].selectedbanks);

        }
        loadsubusers();
        loadUserslov();
    }, [cusid])
    const Getprofileimg = (a) => {
        //alert(a);
        let promig;
        if (a != '' && a != 'null' && a != null && a != undefined) {
            promig = base64_decode(a)
        }
        else {
            promig = `${process.env.PUBLIC_URL}/profileimg.png`;
        }
        return promig;
    }
    return (
        <Fragment>
            <Breadcrumb title="Customer View" parent="Customer List" link="/customer" subparent="Customer View" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid={true}>
                <Row>

                    <Col sm="12">
                        <Card >

                            {Users.map((res, a) =>
                                <CardBody key={a} style={{ display: 'flex' }} >

                                    <div style={{ width: '50%', backgroundColor: '#f3f9fb', padding: '30px', borderRadius: '20px' }}>

                                        <Row style={{ padding: '10px' }}>
                                            <Col sm="4" style={{ textAlign: 'right' }}>
                                                <b>Company:</b>
                                            </Col>
                                            <Col sm="8" style={{ textAlign: 'left' }}>
                                                <b> {res.companyname}</b>
                                            </Col>


                                        </Row>
                                        <Row style={{ padding: '10px' }}>
                                            <Col sm="4" style={{ textAlign: 'right' }}>
                                                <b>FullName:</b>
                                            </Col>
                                            <Col sm="8" style={{ textAlign: 'left' }}>
                                                {res.fullname}
                                            </Col>


                                        </Row>
                                        <Row style={{ padding: '10px' }}>
                                            <Col sm="4" style={{ textAlign: 'right' }}>
                                                <b>Email:</b>
                                            </Col>
                                            <Col sm="8" style={{ textAlign: 'left' }}>
                                                {res.email}
                                            </Col>


                                        </Row>
                                        <Row style={{ padding: '10px' }}>
                                            <Col sm="4" style={{ textAlign: 'right' }}>
                                                <b>Mobile:</b>
                                            </Col>
                                            <Col sm="8" style={{ textAlign: 'left' }}>
                                                {res.mobile}
                                            </Col>


                                        </Row>
                                        <Row style={{ padding: '10px' }}>
                                            <Col sm="4" style={{ textAlign: 'right' }}>
                                                <b>Organization Type:</b>
                                            </Col>
                                            <Col sm="8" style={{ textAlign: 'left' }}>
                                                {res.orgtype}
                                            </Col>


                                        </Row>
                                        <Row style={{ padding: '10px' }}>
                                            <Col sm="4" style={{ textAlign: 'right' }}>
                                                <b>JOB Title:</b>
                                            </Col>
                                            <Col sm="8" style={{ textAlign: 'left' }}>
                                                {res.jobtitle}
                                            </Col>


                                        </Row>
                                        <Row style={{ padding: '10px' }}>
                                            <Col sm="4" style={{ textAlign: 'right' }}>
                                                <b>Address:</b>
                                            </Col>
                                            <Col sm="8" style={{ textAlign: 'left' }}>
                                                <p>{res.add1}</p>
                                                <p>{res.add2}</p>
                                                <p>
                                                    {res.city},{res.state}</p>
                                                <p>   {res.country},
                                                    {res.postcode}
                                                </p>
                                            </Col>


                                        </Row>
                                        <Row style={{ padding: '10px' }}>
                                            <Col sm="4" style={{ textAlign: 'right' }}>
                                                <b>Date Created:</b>
                                            </Col>
                                            <Col sm="8" style={{ textAlign: 'left' }}>
                                                {res.datecreated}
                                            </Col>


                                        </Row>
                                    </div>
                                    <div style={{ width: '10%' }}></div>
                                    <div style={{ width: '40%' }}>
                                        <Row style={{ backgroundColor: '#f3f9fb', padding: '30px', borderRadius: '20px' }}>
                                            <Col sm="12" style={{ textAlign: 'center' }}><b>Workspace</b></Col>
                                            <Col sm="12" style={{ textAlign: 'center' }}><h5 style={{ color: 'blue' }}>{res.workspace}.laabamone.com</h5></Col>

                                        </Row>
                                        <Row style={{ backgroundColor: '#f3f9fb', padding: '30px', borderRadius: '20px' }}>
                                            <Col sm="12" style={{ textAlign: 'center' }}><b>status</b></Col>
                                            <Col sm="12" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                <Badge color="primary"> {res.status == '1' ? 'Active' : 'Inactive'}</Badge>
                                            </Col>

                                        </Row>
                                        <Row>
                                        </Row>
                                    </div>


                                </CardBody>)}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm="3"></Col>
                    <Col sm="5">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>S.no</b></TableCell>
                                    <TableCell><b>Username</b></TableCell>
                                    <TableCell><b>date</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subUsers.map((res, a) => res.id != 0 ?


                                    <TableRow>
                                        <TableCell > {res.sno}</TableCell>
                                        <TableCell > {res.email}</TableCell>
                                        <TableCell > {res.date}</TableCell>
                                    </TableRow>


                                    :
                                    <TableRow>
                                        <TableCell colSpan={2}>No Data Available</TableCell>
                                    </TableRow>
                                )}


                            </TableBody>
                        </Table>

                    </Col>
                    <Col sm="4"></Col>
                </Row>
            </Container>
        </Fragment>

    );
}
export default Listview;