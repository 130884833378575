import React from "react";

export const data = [
	{
		id: "1",
		title: "10% Off",
		code: " Percent10",
		discount: "10%",
		status: <span className="fa fa-circle font-success f-12"></span>,
	},
	{
		id: "2",
		title: "25% Off",
		code: " Percent25",
		discount: "25%",
		status: <span className="fa fa-circle font-warning  f-12"></span>,
	},
	{
		id: "3",
		title: "5% Off",
		code: " Percent5",
		discount: "5%",
		status: <span className="fa fa-circle font-success f-12"></span>,
	},
	{
		id: "4",
		title: "2% Off",
		code: " Percent2",
		discount: "2%",
		status: <span className="fa fa-circle font-warning f-12"></span>,
	},
	{
		id: "5",
		title: "50% Off",
		code: " Percent50",
		discount: "50%",
		status: <span className="fa fa-circle font-danger f-12"></span>,
	},
	{
		id: "6",
		title: "70% Off",
		code: " Percent70",
		discount: "70%",
		status: <span className="fa fa-circle font-success f-12"></span>,
	},
	{
		id: "7",
		title: "75% Off",
		code: " Percent75",
		discount: "75%",
		status: <span className="fa fa-circle font-danger f-12"></span>,
	},
	{
		id: "8",
		title: "80% Off",
		code: " Percent80",
		discount: "80%",
		status: <span className="fa fa-circle font-success f-12"></span>,
	},
];

export default data;
