import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Row, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter } from "reactstrap";
import Breadcrumb from "../../common/breadcrumb";
import { useParams } from "react-router-dom";
import axios from "axios";
import jsCookie from 'js-cookie';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
const Listview = () => {
    jsCookie.set('pagename', 'career');
    const { cusid } = useParams();
    console.log(cusid);
    const [Users, setUsers] = useState([]);
    const [Banks, setBanks] = useState([]);
    useEffect(() => {
        const loadUserslov = async () => {
            const response = await axios.get("https://www.laabamone.com/appoint_api.php?eventtype=getcareerlist&id=" + cusid);
            console.log('loc', response.data);
            setUsers(response.data);
            // setBanks(response.data[0].selectedbanks);

        }

        loadUserslov();
    }, [cusid])
    const Getprofileimg = (a) => {
        //alert(a);
        let promig;
        if (a != '' && a != 'null' && a != null && a != undefined) {
            promig = base64_decode(a)
        }
        else {
            promig = `${process.env.PUBLIC_URL}/profileimg.png`;
        }
        return promig;
    }
    return (
        <Fragment>
            <Breadcrumb title="Career View" link="/career" parent="Career View" subparent={"Career View"} />

            {/* <!-- Container-fluid starts--> */}
            <Container fluid={true}>
                <Row>

                    <Col sm="12">
                        <Card >

                            {Users.map((res, a) =>
                                <CardBody key={a} style={{ display: 'flex' }} >
                                    <div style={{ width: '50%', backgroundColor: '#f3f9fb', padding: '30px', borderRadius: '20px' }}>


                                        <Row style={{ padding: '10px' }}>
                                            <Col sm="4" style={{ textAlign: 'right' }}>
                                                <b>FullName:</b>
                                            </Col>
                                            <Col sm="8" style={{ textAlign: 'left' }}>
                                                <b> {res.fullname}</b>
                                            </Col>


                                        </Row>
                                        <Row style={{ padding: '10px' }}>
                                            <Col sm="4" style={{ textAlign: 'right' }}>
                                                <b>Email:</b>
                                            </Col>
                                            <Col sm="8" style={{ textAlign: 'left' }}>
                                                {res.email}
                                            </Col>


                                        </Row>
                                        <Row style={{ padding: '10px' }}>
                                            <Col sm="4" style={{ textAlign: 'right' }}>
                                                <b>Mobile:</b>
                                            </Col>
                                            <Col sm="8" style={{ textAlign: 'left' }}>
                                                {res.mobile}
                                            </Col>


                                        </Row>
                                        <Row style={{ padding: '10px' }}>
                                            <Col sm="4" style={{ textAlign: 'right' }}>
                                                <b>Description:</b>
                                            </Col>
                                            <Col sm="8" style={{ textAlign: 'left' }}>
                                                <p> {res.description}</p>
                                            </Col>


                                        </Row>


                                        <Row style={{ padding: '10px' }}>
                                            <Col sm="4" style={{ textAlign: 'right' }}>
                                                <b>Address:</b>
                                            </Col>
                                            <Col sm="8" style={{ textAlign: 'left' }}>
                                                <p>{res.add1}</p>
                                                <p>{res.add2}</p>
                                                <p>
                                                    {res.city},{res.state}</p>
                                                <p>   {res.country},
                                                    {res.postcode}
                                                </p>
                                            </Col>


                                        </Row>
                                    </div>
                                    <div style={{ width: '10%' }}></div>
                                    <div style={{ width: '40%' }}>
                                        <Row style={{ backgroundColor: '#f3f9fb', padding: '30px', borderRadius: '20px' }}>
                                            <Col sm="12" style={{ textAlign: 'center' }}><b>Resume</b></Col>
                                            <Col sm="12" style={{ textAlign: 'center' }}><h5 style={{ color: 'blue' }}><a href={`${res.file}`} target="_blank">Click here to download.</a></h5></Col>

                                        </Row>
                                        <Row>
                                        </Row>
                                    </div>


                                </CardBody>)}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>

    );
}
export default Listview;