import React, { Fragment, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, Alert } from '@mui/material';
import axios from 'axios';
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Row, ModalHeader, ModalBody, Form, FormGroup, Label, ModalFooter } from "reactstrap";
import InputAdornment from '@mui/material/InputAdornment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Breadcrumb from "../../common/breadcrumb";
import jsCookie from 'js-cookie';
export default function Productview({ id }) {
    const { cusid, productid } = useParams();

    console.log('suxid', cusid);
    const [createObjectURL_pimage, setCreateObjectURL_pimage] = useState(null);

    const [chead, setchead] = useState('0');
    const [ctype, setctype] = useState('0');
    const [cname, setcname] = useState('');
    const [camt, setcamt] = useState('');
    const [cstatus, setcstatus] = useState('1');
    const [pimage, setpImage] = useState([]);
    const [sizeerr, setsizeerr] = useState('');
    const [Errorval, setErrorval] = useState('');
    const [pdescription, setpdescription] = useState('');
    const [plink, setplink] = useState('');
    const [Buttonloader, setButtonloader] = useState('1');
    const [profiledata, setprofiledata] = useState('');
    const [arraychead, setarraychead] = useState('');
    const [arrayctype, setarrayctype] = useState('');
    const [pnameerror, setpnameerror] = useState('');


    useEffect(() => {
        jsCookie.set('pagename', 'card');
        // Perform localStorage action
        const getCard = async (a) => {

            const response = await fetch('https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=card_list&id=' + a);
            const json = await response.json();
            console.log(json);
            setprofiledata(json);
            setchead(json[0].headid)
            setctype(json[0].tyepid)
            setcname(json[0].name)
            setcamt(json[0].amt)
            setpdescription(json[0].desc);
            setCreateObjectURL_pimage(json[0].img);


        }


        const getCard_head = async () => {

            const response = await fetch('https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=head_card_list');
            const json = await response.json();
            console.log(json);
            setarraychead(json[0].head_list);
            setarrayctype(json[0].type_list);
        }




        if (cusid != null && cusid != undefined && cusid != '') {
            getCard(cusid);
        }
        getCard_head();





    }, [productid, cusid])


    const SaveProductImage = (e) => {
        const SIZE = 20 * 1024;
        if (e.target.files && e.target.files[0]) {

            if (e.target.files[0].size > SIZE) {

                setsizeerr('Product Image Size greater than 20kb');
                toast.error("Product Image Size greater than 20kb");
                //console.log("file size greater than " + SIZE);
            } else {
                setsizeerr('');
                const i = e.target.files[0];
                setpImage(i);
                console.log(i);
                setCreateObjectURL_pimage(URL.createObjectURL(i));
            }

            // registerDocument1('1', i);
        }

    }
    const registerDocument1 = async (a) => {



        if (pimage.name != '' && pimage.name != undefined && pimage.name != null) {
            setButtonloader('2');
            let url = "https://api.lobsmartcard.me/Api/Lobsmart/api.php"
            const formData = new FormData();
            formData.append('eventtype', 'lob_addprofile_image');
            formData.append('id', a);
            formData.append('cardimage', pimage);
            let config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            axios.post(url, formData, config)
                .then((response) => {

                    console.log('result', response);
                    setButtonloader('1');
                    //  toast.success("Updated Successfully...");
                    // window.location.href = "/customeredit/" + a;
                    // setActiveStep('2');

                })
                .catch((error) => {
                    toast.success("Error...");
                    setButtonloader('1');
                    console.log({ status: `upload failed ${error}` });
                })
        }

        toast.success("Added Successfully...");



    }
    const SaveProduct = () => {

        let str = '';
        let error = 0;
        if (chead == '0' || chead == '' || chead == undefined || chead == null) {
            setpnameerror("Required");
            toast.error("Fill Requried Fields")
            error = 1;
        }
        else if (ctype == '0' || ctype == '' || ctype == undefined || ctype == null) {
            setpnameerror("Required");
            toast.error("Fill Requried Fields")
            error = 1;
        }
        else if (cname == '' || cname == undefined || cname == null) {
            setpnameerror("Required");
            toast.error("Fill Requried Fields")
            error = 1;
        }
        else if (camt == '' || camt == undefined || camt == null) {
            setpnameerror("Required");
            toast.error("Fill Requried Fields")
            error = 1;
        }
        if (error == 1) {
            setButtonloader('1');
            return false;
        }
        else {
            setpnameerror("");
            let str = '';
            if (cusid != '' && cusid != null && cusid != undefined) {
                str = "&id=" + cusid;
            }
            console.log('https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=card&viewtype=add'
                + str + '&head_id=' + chead + '&name=' + cname +
                '&desc=' + pdescription +
                '&amount=' + camt +
                '&status=' + cstatus)
            fetch('https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=card&viewtype=add'
                + str + '&head_id=' + chead + '&name=' + cname +
                '&desc=' + pdescription +
                '&amount=' + camt +
                '&status=' + cstatus
            )
                .then((res) => res.json())
                .then(
                    (result) => {
                        console.log('yessssssss');
                        const id = result[0]['id'];
                        const message = result[0]['message'];
                        if (id != undefined && message == 'success') {
                            setButtonloader(1);
                            setcname('');
                            setcamt('');
                            setpdescription('');
                            setpImage('');
                            setchead('');
                            setctype('');
                            registerDocument1(id);
                            setErrorval('');


                        }
                        else {
                            const errormsg = result[0]['errormsg'];
                            toast.error(errormsg)
                            setErrorval(errormsg);
                            setButtonloader(1);
                        }


                        console.log(result);
                    },
                    (error) => {
                        console.log('no');
                        console.log(error);
                        toast.error("Try again..Data not update..");
                        setButtonloader(1);
                    }
                );
        }
    }
    if (arraychead.length > 0) {
        return (
            <Fragment>

                <Breadcrumb title="Card" parent={`Card List `} link={`/card`} subparent={`Card ${cusid != undefined ? 'Edit' : 'Add'}`} />
                {/* <!-- Container-fluid starts--> */}
                <Container fluid={true}>
                    <Row>

                        <Col sm="12">
                            <Card >


                                <CardBody style={{ display: 'flex' }} >


                                    <Box fullWidth sx={{ padding: '20px', borderRadius: '6px', border: '1px solid #557191' }}>

                                        <div className="row col-md-12" >
                                            <div className="col-md-12 " style={{ borderBottom: '.5px solid #557191' }}>
                                                <h3>Card:</h3>
                                            </div>
                                            <div className="col-md-12 " style={{ paddingBottom: '20px' }}>

                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Card Head</InputLabel>

                                                    <Select id="demo-simple-select-helper" value={chead} onChange={(e) => {
                                                        setchead(e.target.value)
                                                        // , setadd2error('') 
                                                    }}>
                                                        <MenuItem value="0">Select Card Head</MenuItem>

                                                        {arraychead.length > 0 &&
                                                            arraychead.map((a, b) =>
                                                                <MenuItem value={a.id}>{a.name}</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Card Type</InputLabel>

                                                    <Select fullWidth value={ctype} onChange={(e) => {
                                                        setctype(e.target.value)
                                                        // , setadd2error('') 
                                                    }}>
                                                        <MenuItem value="0">Select Card Type</MenuItem>

                                                        {arrayctype.length > 0 &&
                                                            arrayctype.map((a, b) =>
                                                                <MenuItem value={a.id}>{a.name}</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>

                                                <TextField
                                                    value={cname}
                                                    error={!!pnameerror}
                                                    helperText={pnameerror}
                                                    onChange={(e) => { setcname(e.target.value) }}
                                                    fullWidth label="Card Name: *" variant="outlined">
                                                </TextField>
                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>

                                                <TextField
                                                    value={camt}
                                                    onChange={(e) => {
                                                        setcamt(e.target.value)
                                                        // , setadd2error('') 
                                                    }}
                                                    fullWidth label="Amount" variant="outlined"


                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                                                    }}
                                                >


                                                </TextField>
                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>

                                                <TextField
                                                    rows={4}
                                                    multiline
                                                    value={pdescription}
                                                    onChange={(e) => {
                                                        setpdescription(e.target.value)
                                                        // , setadd2error('') 
                                                    }}
                                                    fullWidth label="Description::" variant="outlined">


                                                </TextField>
                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                                    <Select fullWidth value={cstatus} onChange={(e) => {
                                                        setcstatus(e.target.value)
                                                        // , setadd2error('') 
                                                    }}>

                                                        <MenuItem value="1">Active</MenuItem>
                                                        <MenuItem value="0">InActive</MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </div>

                                            <div className="row col-md-12" style={{ padding: '20px 20px' }}>
                                                <div className="col-md-12 " >
                                                    <h4>Card Image:</h4>
                                                </div>
                                                <div className="col-md-3 "></div>
                                                <div className="col-md-3 ">

                                                    <Box sx={{ border: '1px dashed #dbdbe3', padding: '55px' }}
                                                        variant="contained"
                                                        component="label"
                                                    >
                                                        <b style={{ fontSize: '25px' }}> +</b>
                                                        <input

                                                            onChange={(e) => SaveProductImage(e)}
                                                            type="file"
                                                            name="myImage"
                                                            accept="image/x-png,image/jpeg,image/jpg"
                                                            hidden
                                                        />
                                                    </Box>
                                                </div>
                                                <div className="col-md-6 ">
                                                    {createObjectURL_pimage != null && createObjectURL_pimage != '' && createObjectURL_pimage != undefined &&
                                                        <img src={createObjectURL_pimage} style={{ width: '150px', height: '150px' }} />
                                                    }

                                                </div>


                                            </div>
                                            {Buttonloader == '1' ?
                                                <button
                                                    className="btn btn-primary btn-square bootstrap-touchspin-down"
                                                    type="button"
                                                    onClick={() => SaveProduct()}
                                                >
                                                    Update
                                                </button>

                                                :
                                                <div className="btn btn-primary btn-square bootstrap-touchspin-down"
                                                >

                                                    <img src="/buttonload3.gif" style={{ height: '30px' }}></img>
                                                </div>
                                            }
                                        </div>

                                    </Box>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
            </Fragment>
        );
    }
    else {
        return (
            <>Loading.</>
        )
    }

}