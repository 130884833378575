export const data = [
	{
		no: "1",
		invoice: "50764",
		date: "1/1/2018",
		shipping: "3.62",
		amount: "816",
		tax: "65.28",
		total: "884.16",
	},
	{
		no: "2",
		invoice: "50765",
		date: "15/3/2018",
		shipping: "8.4",
		amount: "146",
		tax: "70",
		total: "225.4",
	},

	{
		no: "3",
		invoice: "50766",
		date: "28/4/2018",
		shipping: "7.3",
		amount: "47",
		tax: "724",
		total: "789",
	},
	{
		no: "4",
		invoice: "50767",
		date: "31/4/2018",
		shipping: "2.00",
		amount: "478",
		tax: "35",
		total: "512",
	},
	{
		no: "5",
		invoice: "50768",
		date: "2/5/2018",
		shipping: "1.5",
		amount: "1460",
		tax: "45.32",
		total: "1515",
	},
	{
		no: "6",
		invoice: "50769",
		date: "3/5/2018",
		shipping: "8.45",
		amount: "768",
		tax: "17",
		total: "780",
	},
	{
		no: "7",
		invoice: "50770",
		date: "4/5/2018",
		shipping: "25.8",
		amount: "874",
		tax: "27.2",
		total: "945.10",
	},
	{
		no: "8",
		invoice: "50771",
		date: "8/5/2018",
		shipping: "17.2",
		amount: "671",
		tax: "83.7",
		total: "775.9",
	},
	{
		no: "9",
		invoice: "50772",
		date: "12/5/2018",
		shipping: "87.5",
		amount: "572",
		tax: "8.70",
		total: "615",
	},
	{
		no: "10",
		invoice: "50773",
		date: "16/5/2018",
		shipping: "78.12",
		amount: "489",
		tax: "10.57",
		total: "516",
	},
	{
		no: "11",
		invoice: "50774",
		date: "20/5/2018",
		shipping: "57.56",
		amount: "800",
		tax: "78.2",
		total: "975",
	},
	{
		no: "12",
		invoice: "50775",
		date: "22/5/2018",
		shipping: "40.17",
		amount: "867",
		tax: "57.2",
		total: "945",
	},
	{
		no: "13",
		invoice: "50776",
		date: "25/5/2018",
		shipping: "80.04",
		amount: "4782",
		tax: "47.5",
		total: "5354",
	},
	{
		no: "14",
		invoice: "50777",
		date: "27/5/2018",
		shipping: "6.15",
		amount: "576",
		tax: "4.48",
		total: "599",
	},
	{
		no: "15",
		invoice: "50778",
		date: "29/5/2018",
		shipping: "8.67",
		amount: "875",
		tax: "56.78",
		total: "987",
	},
	{
		no: "16",
		invoice: "50779",
		date: "5/6/2018",
		shipping: "7.88",
		amount: "547",
		tax: "5.72",
		total: "600",
	},
	{
		no: "17",
		invoice: "50780",
		date: "8/6/2018",
		shipping: "3.14",
		amount: "781",
		tax: "23.47",
		total: "817",
	},

	{
		no: "18",
		invoice: "50781",
		date: "14/6/2018",
		shipping: "7.3",
		amount: "47",
		tax: "724",
		total: "789",
	},
	{
		no: "19",
		invoice: "50782",
		date: "18/6/2018",
		shipping: "2.00",
		amount: "478",
		tax: "35",
		total: "512",
	},
	{
		no: "20",
		invoice: "50783",
		date: "21/6/2018",
		shipping: "1.5",
		amount: "1460",
		tax: "45.32",
		total: "1515",
	},
	{
		no: "21",
		invoice: "50784",
		date: "23/6/2018",
		shipping: "8.45",
		amount: "768",
		tax: "17",
		total: "780",
	},
	{
		no: "22",
		invoice: "50785",
		date: "26/6/2018",
		shipping: "25.8",
		amount: "874",
		tax: "27.2",
		total: "945.10",
	},
	{
		no: "23",
		invoice: "50786",
		date: "27/6/2018",
		shipping: "17.2",
		amount: "671",
		tax: "83.7",
		total: "775.9",
	},
	{
		no: "24",
		invoice: "50787",
		date: "30/6/2018",
		shipping: "87.5",
		amount: "572",
		tax: "8.70",
		total: "615",
	},
];

export default data;
