import React, { useState, useEffect, Fragment } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Card, CardBody, CardHeader, Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropzone from "../../common/dropzone";
import { useParams } from "react-router-dom";
import axios from 'axios';
import jsCookie from 'js-cookie';
//import CryptoJS from 'crypto-js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
//import LocalizationProvider from '@mui/lab/LocalizationProvider'; 
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Moment from 'moment';
import DatePicker from '@mui/lab/DatePicker';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ExternalLink } from "react-feather";
const TabsetUser = () => {
	const { helperid, resumeid } = useParams();
	console.log('idd', helperid, resumeid)
	const [value, setValue] = React.useState(null);
	const [passvalue, setpassValue] = React.useState(null);
	const [startdatevalue, setstartdateValue] = React.useState(null);
	const [getHelperDet, setHelperdetails] = useState([]);
	const [getCountryPrefertowork, setCountryPrefertowork] = useState([]);
	const [getCurrentlyLocated, setCurrentlyLocated] = useState([]);


	const [country, setCountry] = useState([]);
	const [user, setUsers] = useState([]);
	const [getuser, setUser] = useState([]);
	//const [getuser, setEmp] = useState([]);
	const [helper, setHelper] = useState([]);
	const [err, setErr] = useState([]);
	const [suc, setSuc] = useState([]);
	const [gifbutto, setGifbutt] = useState('');

	const [InputSalutation, setInputSalutation] = useState([]);
	const [Inputname, setInputname] = useState([]);
	const [InputNationality, setInputNationality] = useState([]);
	const [InputGender, setInputGender] = useState([]);
	const [InputReligion, setInputReligion] = useState([]);
	const [InputCurrentLocation, setInputCurrentLocation] = useState([]);
	const [InputHeight, setInputHeight] = useState([]);
	const [InputWeight, setInputWeight] = useState([]);
	const [InputPlaceofBirth, setInputPlaceofBirth] = useState([]);
	const [useridadd, setUSerid] = useState([]);
	const [inputpass, setInputpass] = useState([]);
	const [InputPassportNo, setInputPassportNo] = useState([]);
	const [InputPassportExpiryDate, setInputPassportExpiryDate] = useState([]);
	const [InputNameofAirportRepatriated, setInputNameofAirportRepatriated] = useState([]);
	const [InputHomeAddress, setInputHomeAddress] = useState([]);
	const [InputHomeAddress2, setInputHomeAddress2] = useState([]);
	const [InputHomeCity, setInputHomeCity] = useState([]);
	const [InputHomeState, setInputHomeState] = useState([]);
	const [InputHomeCountry, setInputHomeCountry] = useState([]);
	const [InputHomePostal, setInputHomePostal] = useState([]);
	const [InputNoOfChildren, setInputNoOfChildren] = useState([]);
	const [InputAgeOfChildren, setInputAgeOfChildren] = useState([]);
	const [InputNoOfSibilings, setInputNoOfSibilings] = useState([]);
	const [InputEducationLevel, setInputEducationLevel] = useState([]);
	const [InputMaritialStatus, setInputMaritialStatus] = useState([]);
	const [InputFoodHandlingPreference, setInputFoodHandlingPreference] = useState([]);
	const [InputLanguage, setInputLanguage] = useState([]);
	const [InputCooking, setInputCooking] = useState([]);
	const [InputMainskills, setInputMainskills] = useState([]);
	const [InputSpecialSkills, setInputSpecialSkills] = useState([]);
	const [InputPersonality, setInputPersonality] = useState([]);
	const [InputStartDate, setInputStartDate] = useState([]);
	const [InputSalary, setInputSalary] = useState([]);
	const [InputAccommodation, setInputAccommodation] = useState([]);
	const [InputOffDay, setInputOffDay] = useState([]);
	const [InputOthers, setInputOthers] = useState([]);
	const [InputDietaryPreference, setInputDietaryPreference] = useState([]);
	const [InputDescription, setInputDescription] = useState([]);



	const [inputmobile, setInputmobile] = useState([]);
	const [inputoccu, setInputoccu] = useState([]);
	const [inputdob, setInputdob] = useState([]);


	const [checkedAllergies, setCheckedAllergies] = React.useState(false);
	const [checkedMentalIllness, setCheckedMentalIllness] = React.useState(false);
	const [checkedEpilepsy, setCheckedEpilepsy] = React.useState(false);
	const [checkedAsthma, setCheckedAsthma] = React.useState(false);
	const [checkedDiabetes, setCheckedDiabetes] = React.useState(false);
	const [checkedHypertension, setCheckedHypertension] = React.useState(false);
	const [checkedTuberculosis, setCheckedTuberculosis] = React.useState(false);
	const [checkedHeartdisease, setCheckedHeartdisease] = React.useState(false);
	const [checkedMalaria, setCheckedMalaria] = React.useState(false);
	const [checkedOperations, setCheckedOperations] = React.useState(false);
	const [checkedPhysicaldisablilities, setCheckedPhysicaldisablilities] = React.useState(false);


	//const [getCountryPrefertowork, setCountryPrefertowork] = useState([]);
	// const [getCurrentlyLocated, setCurrentlyLocated] = useState([]); 	
	const [getCountryeverworked, setCountryeverworked] = useState([]);
	const [checkedDomestichelper, setCheckedDomestichelper] = React.useState(false);
	const [checkedcurrentDomestichelper, setCheckedcurrentDomestichelper] = React.useState(false);
	const [getExpectedSalary, setExpectedSalary] = useState([]);

	const [InputDomestichelper, setInputDomestichelper] = useState([]);
	const [InputCountryeverworked, setInputCountryeverworked] = useState([]);
	const [InputCurrentdomestichelper, setInputCurrentdomestichelper] = useState([]);
	const [InputCurrentlyLocated, setInputCurrentlyLocated] = useState([]);
	const [InputCountryPrefertowork, setInputCountryPrefertowork] = useState([]);
	const [InputExpectedSalary, setInputExpectedSalary] = useState([]);

	// const [inputname, setInputname] = useState([]);
	// const [inputmobile, setInputmobile] = useState([]);
	const [inputuseremail, setInputuseremail] = useState([]);
	const [inputadd1, setInputadd1] = useState([]);
	const [inputadd2, setInputadd2] = useState([]);
	const [inputcity, setInputcity] = useState([]);
	const [inputpcode, setInputpcode] = useState([]);
	const [inputnation, setInputnation] = useState([]);
	//const [inputoccu, setInputoccu] = useState([]);
	// const [inputdob, setInputdob] = useState([]);


	const setValue1 = (date) => {

		console.log('split', date)
		// let l = date.split('/');
		setValue(date);
	};
	const calculate_age = (dob1) => {
		var today = new Date();
		var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
		var age_now = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age_now--;
		}
		console.log(age_now);
		return age_now;
	}
	const handleChangeDomestichelper = (event) => {
		setCheckedDomestichelper(!checkedDomestichelper);
	};
	const handleChangecurrentDomestichelper = (event) => {
		setCheckedcurrentDomestichelper(!checkedcurrentDomestichelper);
	};

	const handleChangeAllergies = (event) => {
		setCheckedAllergies(!checkedAllergies);
	};
	const handleChangeMentalIllness = (event) => {
		setCheckedMentalIllness(!checkedMentalIllness);
	};
	const handleChangeEpilepsy = (event) => {
		setCheckedEpilepsy(!checkedEpilepsy);
	};
	const handleChangeAsthma = (event) => {
		setCheckedAsthma(!checkedAsthma);
	};
	const handleChangeDiabetes = (event) => {
		setCheckedDiabetes(!checkedDiabetes);
	};
	const handleChangeHypertension = (event) => {
		setCheckedHypertension(!checkedHypertension);
	};
	const handleChangeTuberculosis = (event) => {
		setCheckedTuberculosis(!checkedTuberculosis);
	};
	const handleChangeHeartdisease = (event) => {
		setCheckedHeartdisease(!checkedHeartdisease);
	};
	const handleChangeMalaria = (event) => {
		setCheckedMalaria(!checkedMalaria);
	};
	const handleChangeOperations = (event) => {
		setCheckedOperations(!checkedOperations);
	};
	const handleChangePhysicaldisablilities = (event) => {
		setCheckedPhysicaldisablilities(!checkedPhysicaldisablilities);
	};
	const [inputListjobscope, setInputListjobscope] = useState([{ jobscope: "" }]);

	const handleInputChangejobscope = (e, index) => {
		const { name, value } = e.target;
		const list = [...inputListjobscope];
		list[index][name] = value;
		setInputListjobscope(list);
	};

	// handle click event of the Remove button
	const handleRemoveClickjobscope = index => {

		const list = [...inputListjobscope];
		list.splice(index, 1);
		setInputListjobscope(list);
	};

	// handle click event of the Add button
	const handleAddClickjobscope = () => {
		setInputListjobscope([...inputListjobscope, { jobscope: "" }]);
	};
	const [inputListcook, setInputListcook] = useState([{ cook: "" }]);

	const handleInputChangecook = (e, index) => {
		const { name, value } = e.target;
		const list = [...inputListcook];
		list[index][name] = value;
		setInputListjobscope(list);
	};

	// handle click event of the Remove button
	const handleRemoveClickcook = index => {

		const list = [...inputListcook];
		list.splice(index, 1);
		setInputListcook(list);
	};

	// handle click event of the Add button
	const handleAddClickcook = () => {
		setInputListcook([...inputListcook, { cook: "" }]);
	};
	const [inputListfood, setInputListfood] = useState([{ food: "" }]);

	const handleInputChangefood = (e, index) => {
		const { name, value } = e.target;
		const list = [...inputListfood];
		list[index][name] = value;
		setInputListjobscope(list);
	};

	// handle click event of the Remove button
	const handleRemoveClickfood = index => {

		const list = [...inputListfood];
		list.splice(index, 1);
		setInputListfood(list);
	};

	// handle click event of the Add button
	const handleAddClickfood = () => {
		setInputListfood([...inputListfood, { food: "" }]);
	};
	const [inputListaccom, setInputListaccom] = useState([{ accom: "" }]);

	const handleInputChangeaccom = (e, index) => {
		const { name, value } = e.target;
		const list = [...inputListaccom];
		list[index][name] = value;
		setInputListjobscope(list);
	};

	// handle click event of the Remove button
	const handleRemoveClickaccom = index => {

		const list = [...inputListaccom];
		list.splice(index, 1);
		setInputListaccom(list);
	};

	// handle click event of the Add button
	const handleAddClickaccom = () => {
		setInputListaccom([...inputListaccom, { accom: "" }]);
	};
	useEffect(() => {



		const loadCountry = async () => {
			const responsec = await axios.get("https://api.laabamone.com/api/v1/Masters/Country");
			setCountry(responsec.data.$values);
			console.log('data', responsec.data.$values);

		}
		const loadUsers = async (Val) => {
			var emp_id = Val;

			if (emp_id != '' && emp_id != null && emp_id != undefined) {


				const response = await axios.get("https://api.laabamone.com/api/v1/GetUserByID?ID=" + emp_id);
				console.log('userdetails', response.data);
				setUser(response.data);
			}

		}
		const loadHelper = async () => {


			if (helperid != '' && helperid != null && helperid != undefined) {

				const response2 = await axios.get("https://api.laabamone.com/api/v1/Masters/GetHelperListByID?ID=" + helperid);
				console.log('helperdetails', response2.data);
				setHelper(response2.data);
				loadUsers(response2.data.UserId);
			}

		}
		const loadHelperdet = async () => {
			var Helperidtext = helperid;
			if (Helperidtext != '' && Helperidtext != null && Helperidtext != undefined) {


				const response2 = await axios.get("https://api.laabamone.com/api/v1/Masters/GetResumeByID?ID=" + Helperidtext);
				console.log('resumen details', response2.data);
				setHelperdetails(response2.data);

				let strr = response2.data.Name;
				let splitname = strr.split(' ')
				setInputSalutation(splitname[0]);
				setInputname(splitname[1] + ' ' + splitname[2] + ' ' + splitname[3]);
				setInputNationality(response2.data.Nationality);
				setInputGender(response2.data.Gender);
				setValue1(response2.data.DateOfBirth);
				setInputReligion(response2.data.Religion);
				setInputCurrentLocation(response2.data.CurrentLocation);
				setInputHeight(response2.data.Height);
				setInputWeight(response2.data.Weight);
				setInputPlaceofBirth(response2.data.PlaceofBirth);
				setInputPassportNo(response2.data.PassportNo);
				setpassValue(response2.data.PassportExpiryDate);
				setInputNameofAirportRepatriated(response2.data.NameofAirportRepatriated);
				setInputHomeAddress(response2.data.HomeAddress);
				setInputHomeAddress2(response2.data.HomeAddress2);
				setInputHomeCity(response2.data.HomeCity);
				setInputHomeState(response2.data.HomeState);
				setInputHomeCountry(response2.data.HomeCountry);

				setInputHomePostal(response2.data.HomePostal);

				setInputNoOfChildren(response2.data.NoOfChildren);
				setInputAgeOfChildren(response2.data.AgeOfChildren);
				setInputNoOfSibilings(response2.data.NoOfSibilings);
				setInputEducationLevel(response2.data.EducationLevel);
				setInputMaritialStatus(response2.data.MaritialStatus);
				setInputFoodHandlingPreference(response2.data.FoodHandlingPreference);
				setInputLanguage(response2.data.Language);
				setInputMainskills(response2.data.Mainskills);
				setInputCooking(response2.data.Cooking);
				setInputSpecialSkills(response2.data.SpecialSkills);
				setInputPersonality(response2.data.Personality);
				setstartdateValue(response2.data.StartDate);
				setInputDietaryPreference(response2.data.DietaryPreference);
				setInputDescription(response2.data.Description);
				setInputSalary(response2.data.Salary);
				setInputAccommodation(response2.data.Accommodation);
				setInputOffDay(response2.data.OffDay);
				//  setInputAllergies(response2.data.Allergies);
				setCheckedAllergies(response2.data.Allergies)
				setCheckedMentalIllness(response2.data.MentalIllness)
				setCheckedEpilepsy(response2.data.Epilepsy)
				setCheckedAsthma(response2.data.Asthma)
				setCheckedDiabetes(response2.data.Diabetes)
				setCheckedHypertension(response2.data.Hypertension)
				setCheckedTuberculosis(response2.data.Tuberculosis)
				setCheckedHeartdisease(response2.data.Heartdisease)
				setCheckedMalaria(response2.data.Malaria)
				setCheckedOperations(response2.data.Operations)
				setInputOthers(response2.data.Others);
				setCheckedPhysicaldisablilities(response2.data.Physicaldisablilities)
				setExpectedSalary(response2.data.ExpectedSalary)
				if (response2.data.CountryPrefertowork != '') {
					let cnt = response2.data.CountryPrefertowork;
					let cnt2 = cnt.split(',');
					setCountryPrefertowork(cnt2);

				}
				if (response2.data.Countryeverworked != '') {
					let cnt = response2.data.Countryeverworked;
					let cnt2 = cnt.split(',');
					setCountryeverworked(cnt2);

				}


				setInputCurrentlyLocated(response2.data.CurrentlyLocated)

			}


		}
		if (helperid != '' && helperid != undefined) {
			loadHelper();
			loadHelperdet();
			//loadUsers();
		}



		loadCountry();
		setGifbutt('');
	}, [helperid, resumeid])
	const registerUser1 = async event => {
		event.preventDefault()

		let type = "Helper";
		let url = "";
		console.log('email', event.target.email.value);
		console.log('password', event.target.password.value);
		console.log('mob_no', event.target.mob_no.value);
		console.log('mob_code', event.target.mob_code.value);
		console.log('status', event.target.status.value);
		console.log('type', type);

		if (helper.UserId != '' && helper.UserId != null) {

			const response = await fetch("https://api.laabamone.com/api/v1/UpdateProfile", {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify({ ID: helper.UserId, Password: event.target.password.value, Status: event.target.status.value, ContactNumber: event.target.mob_no.value, SourceCountry: event.target.mob_code.value, UserTypes: type }),

			});
			const data = await response.json();
			console.log('dataed', data);
			const errmessage = data.ErrorMessages.$values[0];

			const res = data.ResponseCode;
			if (res == 'Created') {

				//	setGifbutt('/assets/images/loading.gif'); 
				setErr('');
				setSuc('Successfully Updated..');


			}
			else {
				setErr(errmessage);
				setSuc('');
			}



		}
		else {
			const response = await fetch("https://api.laabamone.com/api/v1/CreateProfile", {

				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify({ Email: event.target.email.value, Password: event.target.password.value, ContactNumber: event.target.mob_no.value, SourceCountry: event.target.mob_code.value, UserTypes: type }),

			});
			const data = await response.json();
			console.log('data', data);
			const errmessage = data.ErrorMessages.$values[0];
			//url =`accounts/getuser/edit/${empid}`;

			const res = data.ResponseCode;
			if (res == 'Created') {
				const userid1 = data.Item['ID'];
				console.log('userid', userid1);

				setUSerid(userid1);
				jsCookie.set('empuserid', userid1);
				CreateHelper();

			}
			else {
				setErr(errmessage);

			}
			//window.location.href = `${url}`;

		}


	}

	const CreateHelper = async event => {

		let type = "";
		let url = "";
		let role = "2";
		var userid = jsCookie.get('empuserid');
		console.log('useridadd', userid)
		//  var userid = useridadd; 


		const response = await fetch("https://api.laabamone.com/api/v1/Masters/CreateHelper", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify({ HelperId: '0', UserId: userid, SupplierId: '0', ResumeId: '0', DocumentsId: '0', JobsId: '0', ChatsId: '0', Salary: '0', SupplierFee: '0' }),

		});
		const data = await response.json();
		const rescode = data.ResponseCode;
		console.log('data', data);

		if (rescode == 'Created') {
			jsCookie.set('Helperid', data.Item.HelperId);
			setErr(rescode);

		}
		else {
			console.log('ttttttt');
		}


	}


	const registerHelper = async event => {
		event.preventDefault();
		setGifbutt('/loading.gif');

		let HELPERID = '';
		if (helperid != undefined) {
			HELPERID = helperid;
		}
		else {
			HELPERID = jsCookie.get('Helperid');
		}

		const dob2 = value;
		let dob1 = Moment(`${dob2}`).format('DD/MM/YYYY');
		let ddate = dob1.split("/");
		const dob = ddate[2] + '-' + ddate[1] + '-' + ddate[0];

		const PassportExpiryDate2 = passvalue;
		let PassportExpiryDate1 = Moment(`${PassportExpiryDate2}`).format('DD/MM/YYYY');
		let expdate = PassportExpiryDate1.split("/");
		const PassportExpiryDate = expdate[2] + '-' + expdate[1] + '-' + expdate[0];

		let StartDate2 = startdatevalue;
		let StartDate1 = Moment(`${StartDate2}`).format('DD/MM/YYYY');
		let sdate = StartDate1.split("/");
		let StartDate11 = sdate[2] + '-' + sdate[1] + '-' + sdate[0];


		let MentalIllness = checkedMentalIllness
		let Epilepsy = checkedEpilepsy
		console.log('checkedMentalIllness', checkedMentalIllness);
		let Hypertension = checkedHypertension
		let Asthma = checkedAsthma
		let Diabetes = checkedDiabetes
		let Tuberculosis = checkedTuberculosis
		let Heartdisease = checkedHeartdisease
		let Malaria = checkedMalaria
		let Operations = checkedOperations

		let a = '';
		console.log('len', inputListcook.length);

		let cooksss = '';
		let inputListcookLength = inputListcook.length;
		for (let i = 0; i < inputListcookLength; i++) {

			if (i == '0') { cooksss += inputListcook[i].cooking_skill; }
			else {
				cooksss += ',';
				cooksss += inputListcook[i].cooking_skill;
			}
		}
		let foodd = '';
		let inputListfoodLength = inputListfood.length;
		for (let i = 0; i < inputListfoodLength; i++) {

			if (i == '0') { foodd += inputListfood[i].food_handling; }
			else {
				foodd += ',';
				foodd += inputListfood[i].food_handling;
			}
		}
		let accommm = '';
		let inputListaccomLength = inputListaccom.length;
		for (let i = 0; i < inputListaccomLength; i++) {

			if (i == '0') {

				if (inputListaccom[i].accommodation != '') {
					accommm += inputListaccom[i].accommodation;
				}

			}
			else {

				accommm += ',';
				accommm += inputListaccom[i].accommodation;

			}
		}
		let precont = '';
		let PreferredContactLength = country.length;
		for (let i = 0; i < PreferredContactLength; i++) {

			if (event.target.everworked[i].checked == true) {
				if (precont != '') {
					precont += ','
					precont += event.target.everworked[i].value
				}
				else { precont += event.target.everworked[i].value }

			}
		}
		let prwork = '';
		let PrefertoworkLength = country.length;
		for (let i = 0; i < PrefertoworkLength; i++) {

			if (event.target.CountryPrefertowork[i].checked == true) {
				if (prwork != '') {
					prwork += ','
					prwork += event.target.CountryPrefertowork[i].value
				}
				else { prwork += event.target.CountryPrefertowork[i].value }

			}
		}

		let fullnameee = InputSalutation + ' ' + Inputname;
		jsCookie.set('Name', fullnameee);

		jsCookie.set('Domestichelper', checkedDomestichelper);
		jsCookie.set('currentDomestichelper', checkedcurrentDomestichelper);
		jsCookie.set('Cooking', cooksss);
		jsCookie.set('FoodHandlingPreference', foodd);
		jsCookie.set('Accommodation', accommm);
		jsCookie.set('Countryeverworked', precont);
		jsCookie.set('CountryPrefertowork', prwork);
		jsCookie.set('ExpectedSalary', getExpectedSalary != undefined ? getExpectedSalary : '');
		//	jsCookie.set('Name',event.target.fullname.value);  
		jsCookie.set("Nationality", event.target.Nationality.value);
		jsCookie.set("DateOfBirth", dob);
		jsCookie.set("Gender", event.target.gender.value);
		jsCookie.set("Religion", InputReligion);
		jsCookie.set("Description", event.target.Description.value);
		jsCookie.set("Height", event.target.height.value);
		jsCookie.set("Weight", event.target.weight.value);
		jsCookie.set("PassportNo", event.target.PassportNo.value);
		jsCookie.set("PassportExpiryDate", PassportExpiryDate);
		jsCookie.set("NameofAirportRepatriated", event.target.NameofAirportRepatriated.value);
		jsCookie.set("EducationLevel", event.target.EducationLevel.value);
		jsCookie.set("NoOfSibilings", event.target.NoOfSibilings.value);
		jsCookie.set("MaritialStatus", event.target.MaritialStatus.value);
		jsCookie.set("NoOfChildren", event.target.NoOfChildren.value);
		jsCookie.set("AgeOfChildren", event.target.AgeOfChildren.value);
		jsCookie.set("FoodHandlingPreference", event.target.FoodHandlingPreference.value);
		jsCookie.set("Language", event.target.Language.value);
		jsCookie.set("Cooking", event.target.Cooking.value);
		jsCookie.set("Mainskills", event.target.Mainskills.value);
		jsCookie.set("SpecialSkills", event.target.SpecialSkills.value);
		jsCookie.set("Personality", event.target.Personality.value);
		jsCookie.set("StartDate", StartDate11);
		jsCookie.set("Salary", event.target.Salary.value);
		jsCookie.set("Accommodation", event.target.Accommodation.value);
		jsCookie.set("OffDay", InputOffDay);
		jsCookie.set("Allergies", checkedAllergies);
		jsCookie.set("MentalIllness", MentalIllness);
		jsCookie.set("Epilepsy", Epilepsy);
		jsCookie.set("Asthma", Asthma);
		jsCookie.set("Diabetes", Diabetes);
		jsCookie.set("Hypertension", Hypertension);
		jsCookie.set("Tuberculosis", Tuberculosis);
		jsCookie.set("Heartdisease", Heartdisease);
		jsCookie.set("Malaria", Malaria);
		jsCookie.set("Operations", Operations);
		jsCookie.set("Others", event.target.Others.value);
		jsCookie.set("PlaceofBirth", event.target.PlaceofBirth.value);
		jsCookie.set("HomeAddress2", event.target.address2.value);
		jsCookie.set("HomeCity", event.target.city.value);
		jsCookie.set("HomeState", event.target.state.value);
		jsCookie.set("HomeCountry", event.target.country.value);
		jsCookie.set("HomePostal", event.target.postcode.value);
		jsCookie.set("MobileNo", event.target.mob_no.value);
		jsCookie.set("Email", event.target.Email.value);
		jsCookie.set("DietaryPreference", event.target.DietaryPreference.value);

		if (resumeid != undefined && resumeid != null && resumeid != '') {
			var passdata = {
				"ResumeId": resumeid,
				"HelperId": helperid,
				"Name": jsCookie.get('Name'),
				"Nationality": jsCookie.get('Nationality'),
				"DateOfBirth": jsCookie.get('DateOfBirth'),
				"Gender": jsCookie.get('Gender'),
				"Religion": jsCookie.get('Religion'),
				"CurrentLocation": "0",
				"Description": jsCookie.get('Description'),
				"Height": jsCookie.get('Height'),
				"Weight": jsCookie.get('Weight'),
				"PassportNo": jsCookie.get('PassportNo'),
				"PassportExpiryDate": jsCookie.get('PassportExpiryDate'),
				"NameofAirportRepatriated": jsCookie.get('NameofAirportRepatriated'),
				"HomeAddress": "string",
				"EducationLevel": jsCookie.get('EducationLevel'),
				"NoOfSibilings": jsCookie.get('NoOfSibilings'),
				"MaritialStatus": jsCookie.get('MaritialStatus'),
				"NoOfChildren": jsCookie.get('NoOfChildren'),
				"AgeOfChildren": jsCookie.get('AgeOfChildren'),
				"FoodHandlingPreference": jsCookie.get('FoodHandlingPreference'),
				"Language": InputLanguage,
				"Mainskills": event.target.Mainskills.value,
				"Cooking": jsCookie.get('Cooking'),
				"SpecialSkills": event.target.SpecialSkills.value,
				"Personality": event.target.Personality.value,
				"StartDate": StartDate11,
				"Salary": event.target.Salary.value,
				"Accommodation": jsCookie.get('Accommodation'),
				"OffDay": jsCookie.get('OffDay'),
				"Allergies": jsCookie.get('Allergies'),
				"MentalIllness": jsCookie.get('MentalIllness') == 'true' ? true : false,
				"Epilepsy": jsCookie.get('Epilepsy') == 'true' ? true : false,
				"Asthma": jsCookie.get('Asthma') == 'true' ? true : false,
				"Diabetes": jsCookie.get('Diabetes') == 'true' ? true : false,
				"Hypertension": jsCookie.get('Hypertension') == 'true' ? true : false,
				"Tuberculosis": jsCookie.get('Tuberculosis') == 'true' ? true : false,
				"Heartdisease": jsCookie.get('Heartdisease') == 'true' ? true : false,
				"Malaria": jsCookie.get('Malaria') == 'true' ? true : false,
				"Operations": jsCookie.get('Operations') == 'true' ? true : false,
				"Others": jsCookie.get('Others'),
				"EmploymentExperienceId": 0,
				"PlaceofBirth": jsCookie.get('PlaceofBirth'),
				"HomeAddress2": jsCookie.get('HomeAddress2'),
				"HomeCity": jsCookie.get('HomeCity'),
				"HomeState": jsCookie.get('HomeState'),
				"HomeCountry": jsCookie.get('HomeCountry'),
				"HomePostal": jsCookie.get('HomePostal'),
				"MobileNo": jsCookie.get('MobileNo'),
				"Email": jsCookie.get('Email'),
				"DietaryPreference": jsCookie.get('DietaryPreference'),
				"Physicaldisablilities": false,
				"Domestichelper": jsCookie.get('Domestichelper') == 'true' ? true : false,
				"Countryeverworked": jsCookie.get('Countryeverworked'),
				"Currentdomestichelper": jsCookie.get('Currentdomestichelper') == 'true' ? true : false,
				"CurrentlyLocated": InputCurrentlyLocated,
				"CountryPrefertowork": jsCookie.get('CountryPrefertowork'),
				"ExpectedSalary": jsCookie.get('ExpectedSalary'),
				"PreferredContactMethod": "string",
				"PreferredContactTiming": "string",
				"Howdidyouhearaboutus": "string"
			};


			const response = await fetch("https://api.laabamone.com/api/v1/Masters/UpdateResume", {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify(passdata),

			});
			const dataemp = await response.json();

			console.log('response', dataemp);
			const res = dataemp.ResponseCode;
			if (res == 'Created') {

				setGifbutt('/loading.gif');
				setErr('');
				setSuc('Successfully Updated..');
				setGifbutt('');
				// window.location.href = "/empmyaccount";   
				window.scrollTo(0, 0)
			}
			else {
				setGifbutt('');
				const errmessage = dataemp.title;
				setErr(errmessage);
				setSuc('');
				window.scrollTo(0, 0)
			}



		}

		else {
			var passdata = {
				"ResumeId": 0,
				"HelperId": helperid,
				"Name": jsCookie.get('Name'),
				"Nationality": jsCookie.get('Nationality'),
				"DateOfBirth": jsCookie.get('DateOfBirth'),
				"Gender": jsCookie.get('Gender'),
				"Religion": jsCookie.get('Religion'),
				"CurrentLocation": "0",
				"Description": jsCookie.get('Description'),
				"Height": jsCookie.get('Height'),
				"Weight": jsCookie.get('Weight'),
				"PassportNo": jsCookie.get('PassportNo'),
				"PassportExpiryDate": jsCookie.get('PassportExpiryDate'),
				"NameofAirportRepatriated": jsCookie.get('NameofAirportRepatriated'),
				"HomeAddress": "string",
				"EducationLevel": jsCookie.get('EducationLevel'),
				"NoOfSibilings": jsCookie.get('NoOfSibilings'),
				"MaritialStatus": jsCookie.get('MaritialStatus'),
				"NoOfChildren": jsCookie.get('NoOfChildren'),
				"AgeOfChildren": jsCookie.get('AgeOfChildren'),
				"FoodHandlingPreference": jsCookie.get('FoodHandlingPreference'),
				"Language": InputLanguage,
				"Mainskills": event.target.Mainskills.value,
				"Cooking": jsCookie.get('Cooking'),
				"SpecialSkills": event.target.SpecialSkills.value,
				"Personality": event.target.Personality.value,
				"StartDate": StartDate11,
				"Salary": event.target.Salary.value,
				"Accommodation": jsCookie.get('Accommodation'),
				"OffDay": jsCookie.get('OffDay'),
				"Allergies": jsCookie.get('Allergies'),
				"MentalIllness": jsCookie.get('MentalIllness') == 'true' ? true : false,
				"Epilepsy": jsCookie.get('Epilepsy') == 'true' ? true : false,
				"Asthma": jsCookie.get('Asthma') == 'true' ? true : false,
				"Diabetes": jsCookie.get('Diabetes') == 'true' ? true : false,
				"Hypertension": jsCookie.get('Hypertension') == 'true' ? true : false,
				"Tuberculosis": jsCookie.get('Tuberculosis') == 'true' ? true : false,
				"Heartdisease": jsCookie.get('Heartdisease') == 'true' ? true : false,
				"Malaria": jsCookie.get('Malaria') == 'true' ? true : false,
				"Operations": jsCookie.get('Operations') == 'true' ? true : false,
				"Others": jsCookie.get('Others'),
				"EmploymentExperienceId": 0,
				"PlaceofBirth": jsCookie.get('PlaceofBirth'),
				"HomeAddress2": jsCookie.get('HomeAddress2'),
				"HomeCity": jsCookie.get('HomeCity'),
				"HomeState": jsCookie.get('HomeState'),
				"HomeCountry": jsCookie.get('HomeCountry'),
				"HomePostal": jsCookie.get('HomePostal'),
				"MobileNo": jsCookie.get('MobileNo'),
				"Email": jsCookie.get('Email'),
				"DietaryPreference": jsCookie.get('DietaryPreference'),
				"Physicaldisablilities": false,
				"Domestichelper": jsCookie.get('Domestichelper') == 'true' ? true : false,
				"Countryeverworked": jsCookie.get('Countryeverworked'),
				"Currentdomestichelper": jsCookie.get('Currentdomestichelper') == 'true' ? true : false,
				"CurrentlyLocated": InputCurrentlyLocated,
				"CountryPrefertowork": jsCookie.get('CountryPrefertowork'),
				"ExpectedSalary": jsCookie.get('ExpectedSalary'),
				"PreferredContactMethod": "string",
				"PreferredContactTiming": "string",
				"Howdidyouhearaboutus": "string"
			};


			const response = await fetch("https://api.laabamone.com/api/v1/Masters/CreateResume", {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify(passdata),

			});
			const dataemp = await response.json();

			console.log('response', dataemp);
			const res = dataemp.ResponseCode;
			if (res == 'Created') {

				setGifbutt('/loading.gif');
				setErr('');
				setSuc('Successfully Created..');
				setGifbutt('');
				window.scrollTo(0, 0)

			}
			else {
				setGifbutt('');
				const errmessage = dataemp.title;
				setErr(errmessage);
				setSuc('');
				window.scrollTo(0, 0)
			}
		}








	}


	return (
		<Fragment>
			<Row>Loading</Row>

		</Fragment>
	);
};

export default TabsetUser;
