import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
//import Export from "react-data-table-component"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/scss/react-table.css"
import axios from 'axios';
import Moment from 'moment';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
//import SortIcon from "@material-ui/icons/ArrowDownward";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DownloadIcon from '@mui/icons-material/Download'
import { CSVLink, CSVDownload } from "react-csv";


import {
	Badge,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";

const Datatable = ({ myData, myClass, multiSelectOption, pagination, page, subHeader, subHeaderComponent }) => {
	const [open, setOpen] = useState(false);
	const [checkedValues, setCheckedValues] = useState([]);
	const [data, setData] = useState(myData);
	const [userdata, setUsers1] = useState([]);
	const [getUsemail, setUsemail] = useState([]);
	const [selectedcheckbox, setselectedcheckbox] = useState([]);

	const selectRow = (e, i) => {

		if (!e.target.checked) {
			setCheckedValues(checkedValues.filter((item, j) => i !== item));
		} else {
			checkedValues.push(i);
			setCheckedValues(checkedValues);
		}
	};


	const handleRemoveRow = () => {
		const updatedData = myData.filter(function (el) {
			return checkedValues.indexOf(el.id) < 0;
		});
		setData([...updatedData]);

	}

	const renderEditable = (cellInfo) => {
		return (
			<div
				style={{ backgroundColor: "#557191" }}
				contentEditable
				suppressContentEditableWarning
				onBlur={(e) => {
					data[cellInfo.index][cellInfo.index.id] = e.target.innerHTML;
					setData({ myData: data });
				}}
				dangerouslySetInnerHTML={{
					__html: myData[cellInfo.index][cellInfo.index.id],
				}}
			/>
		);
	};

	/*	const handleDelete = (index) => {
			if (window.confirm("Are you sure you wish to delete this item?")) {
				const del = data;
				del.splice(index, 1);
				setData([...del]);
			}
			toast.success("Successfully Deleted !");
		};*/

	const Getuserstatus = (index) => {
		const stat = '';
		console.log('index', index)
		setUsers1('');
		fetch('https://api.laabamone.com/api/v1/GetUserByID?ID=' + index)
			.then(response => response.json())
			.then(json => setUsers1(json))
		console.log('userdata', userdata.UserNumber);
		return userdata.UserNumber;
		return false;

	}
	const handleDelete = (index) => {
		if (window.confirm("Are you sure you wish to delete this item?")) {
			const del = data;
			del.splice(index, 1);
			setData([...del]);
		}


		const response = axios.get(`https://api.laabamone.com/api/v1/DeleteUserByID?ID=${index}`);
		console.log('dele', response.data);
		toast.success("Successfully Deleted.. !");







	}
	const handleDeleteemp = (index) => {
		if (window.confirm("Are you sure you wish to delete this item?")) {
			const del = data;
			del.splice(index, 1);
			setData([...del]);
		}

		const response = axios.get(`https://api.laabamone.com/api/v1/DeleteEmployerByID?ID=${index}`);
		console.log('dele', response.data);
		window.location.reload();

		toast.success("Successfully Deleted.. !");







	}

	const getUserNum = (index) => {

		//const response =await axios.get(`https://api.laabamone.com/api/v1/GetUserByID?ID=68`);
		//console.log('delerr',response.data.Email);
		//setUsemail(response.data.Email);
		//	console.log('delerr',json);
		return index;
		//	return response.data.Email;

	};

	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};

	const Capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};
	const Getprofileimg = (a) => {
		//alert(a);
		let promig;
		if (a != '' && a != 'null' && a != null && a != undefined) {
			promig = base64_decode(a)
		}
		else {
			promig = `${process.env.PUBLIC_URL}/profileimg.png`;
		}
		return promig;
	}

	/*************************************************************************************** */
	let editurl = '';
	if (page == 'customerlist') {
		editurl = '/customeredit/';
	}
	else if (page == 'careerlist') {
		editurl = '/careerview/';
	}
	else if (page == 'cardlist') {
		editurl = '/cardedit/';
	}
	else if (page == 'orderlist') {
		editurl = '/orderview/';
	}
	else if (page == 'subscriplist') {
		editurl = '/newsletterview/';
	}

	else {
		editurl = '/accounts/employer/edit/';
	}

	const columns = [];
	if (page == 'customerlist') {




		columns.push({
			name: <b style={{ textAlign: 'center' }}>Action</b>,
			id: "delete",
			accessor: (str) => "delete",
			cell: (row, index) => (
				<div>
					<a href={`${editurl}${row.uniqueid}`}><span>
						<i

							className="fa fa-edit"
							style={{
								width: 35,
								fontSize: 20,
								padding: 11,
								color: "rgb(40, 167, 69)",
							}}
						></i>

					</span></a>
					<a href="#" onClick={() => handledatatable(index, 'delete', 'cutomer', row.uniqueid)}>
						<i
							className="fa fa-trash"
							style={{
								width: 35,
								fontSize: 20,
								padding: 11,
								color: "#e4566e",
							}}
						></i>
					</a>
					<a href={`https://www.lobsmartcard.me/p/${row.uriid}`} target="_blank">
						<i
							className="fa fa-external-link"
							style={{
								width: 35,
								fontSize: 20,
								padding: 11,
								color: "#557191",
							}}
						></i>
					</a>


				</div >
			),
			style: {
				textAlign: "center",
			},
			sortable: false,
			maxWidth: '14%',
		});

	}
	if (page == 'orderlist') {




		columns.push({
			name: <b style={{ textAlign: 'center' }}>Action</b>,
			id: "delete",
			accessor: (str) => "delete",
			cell: (row, index) => (
				<div>
					<a href={`${editurl}${row.id}`}><span>
						<i

							className="fa fa-eye"
							style={{
								width: 35,
								fontSize: 20,
								padding: 11,
								color: "rgb(40, 167, 69)",
							}}
						></i>

					</span></a>
					<a href="#" onClick={() => handledatatable(index, 'delete', 'cutomer', row.uniqueid)}>
						<i
							className="fa fa-trash"
							style={{
								width: 35,
								fontSize: 20,
								padding: 11,
								color: "#e4566e",
							}}
						></i>
					</a>



				</div >
			),
			style: {
				textAlign: "center",
			},
			sortable: false,
			maxWidth: '14%',
		});

	}

	if (page == 'cardlist') {




		columns.push({
			name: <b style={{ textAlign: 'center' }}>Action</b>,
			id: "delete",
			accessor: (str) => "delete",
			cell: (row, index) => (
				<div>
					<a href={`${editurl}${row.id}`}>
						<span>
							<i

								className="fa fa-edit"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "rgb(40, 167, 69)",
								}}
							></i>

						</span></a>
					<a href="#" onClick={() => handledatatable(index, 'delete', 'card', row.id)}>
						<i
							className="fa fa-trash"
							style={{
								width: 35,
								fontSize: 20,
								padding: 11,
								color: "#e4566e",
							}}
						></i>
					</a>



				</div >
			),
			style: {
				textAlign: "center",
			},
			sortable: false,
			maxWidth: '14%',
		});

	}

	for (var key in myData[0]) {
		let i = 1;
		let editable = renderEditable;
		let width = '0%';
		let align = "center";
		let contition = '';

		if (page == 'customerlist') {
			contition = (key != "id" && key != 'servicedetails' && key != 'proudctdetails' && key != 'simage' && key != 'sdescription' && key != 'slink' && key != 'pimage' && key != 'pdescription' && key != 'plink' && key != 'sname' && key != 'aboutcontent' && key != 'website' && key != 'phonew' && key != 'phonep' && key != 'org' && key != 'insta' && key != 'pname' && key != 'fb' && key != 'uriid' && key != 'type' && key != 'twitter' && key != 'linkedin' && key != 'youtube' && key != 'password' && key != 'count' && key != 'social_url' && key != 'landmark' && key != 'country' && key != 'message' && key != 'gender' && key != 'flat' && key != 'street' && key != 'state' && key != 'city' && key != 'postcode' && key != 'errormsg' && key != 'designation' && key != 'social' && key != 'lname')

		}
		else if (page == 'cardlist') {
			contition = (key != "id" && key != 'headid' && key != 'tyepid' && key != 'errormsg' && key != 'message')

		}
		else if (page == 'orderlist') {
			contition = (key != "id" && key != 'items' && key != 'address' && key != 'count' && key != 'cusid' && key != 'errormsg' && key != 'message' && key != 'subtotal')

		}
		else if (page == 'careerlist') {
			contition = (key != "id" && key != 'countryval' && key != 'description' && key != 'message' && key != 'date' && key != 'errormsg' && key != 'add1' && key != 'add2' && key != 'country' && key != 'postcode')
		}
		else if (page == 'subscriplist') {
			contition = (key != "id" && key != 'description' && key != 'message' && key != 'date' && key != 'errormsg' && key != 'add1' && key != 'add2' && key != 'city' && key != 'state' && key != 'country' && key != 'postcode')
		}
		else {
			contition = (key != "$id")
		}

		if (contition) {
			if (key === "sno") {
				width = '3%';
				align = "left";
			}
			if (page == 'careerlist') {
				if (key === "fullname") {
					width = '15%';
					align = "left";
				}

				if (key === "mobile") {
					width = '13%';
					align = "left";
				}
				if (key === "state") {
					width = '10%';
					align = "left";
				}
				if (key === "city") {
					width = '10%';
					align = "left";
				}
				if (key === "email") {
					width = '40%';
					align = "left";
				}
			}
			else if (page == 'customerlist') {

				if (key === "Last updated") {
					width = '20%';
					align = "left";
				}
				if (key === "email") {
					width = '20%';
					align = "left";
				}
				if (key === "name") {
					width = '12%';
					align = "left";
				}

				if (key === "mobile") {
					width = '13%';
					align = "left";
				}




			}
			else if (page == 'orderlist') {
				if (key === "orderid") {
					width = '15%';
					align = "left";
				}
				if (key === "total") {
					width = '10%';
					align = "left";
				}
				if (key === "cusname") {
					width = '20%';
					align = "left";
				}
				if (key === "date") {
					width = '30%';
					align = "left";
				}






			}
			else if (page == 'cardlist') {
				if (key === "name") {
					width = '15%';
					align = "left";
				}
				if (key === "headname") {
					width = '15%';
					align = "left";
				}
				if (key === "typename") {
					width = '20%';
					align = "left";
				}







			}
			else if (page == 'subscriplist') {
				if (key === "email") {
					width = '50%';
					align = "left";
				}
				if (key === "name") {
					width = '30%';
					align = "left";
				}
			}
			if (page == 'customerlist') {

				if (key != 'status' && key != 'proimage' && key != 'uniqueid' && key != 'phonewhat') {
					//alert(key);
					columns.push({
						name: <b>{Capitalize(key.toString())}</b>,
						header: <b>{Capitalize(key.toString())}</b>,
						selector: key,
						Cell: editable,
						style: {
							textAlign: align,
						},
						sortable: true,
						maxWidth: width,
					});
				}
				if (key == 'proimage') {
					columns.push({
						name: <b>Profile Image</b>,
						id: "status",
						accessor: (str) => "status",
						cell: (row, index) => (
							<div>
								{row.proimage != '' && (base64_decode(row.proimage) != 'https://api.lobsmartcard.me/Api/Lobsmart/images/') ?
									<img src={base64_decode(row.proimage)} style={{ width: '50px', borderRadius: '50px' }} />
									:
									<img src="https://api.lobsmartcard.me/Api/Lobsmart/images/default.jpg" style={{ width: '50px' }} />
								}



							</div>
						),
						style: {
							textAlign: "center",
						},
						sortable: false,
						maxWidth: '5%',
					});
				}
				{/*if (key == 'uniqueid') {
					columns.push({
						name: <b>#</b>,
						id: "status",
						accessor: (str) => "status",
						cell: (row, index) => (
							<div>

								<Badge color="success"> {row.uniqueid}</Badge>




							</div>
						),
						style: {
							textAlign: "center",
						},
						sortable: false,
						maxWidth: '5%',
					});
				}*/}
				if (key == 'phonewhat') {
					columns.push({
						name: <b>WhatsApp No</b>,
						id: "status",
						accessor: (str) => "status",
						cell: (row, index) => (
							<div>
								{row.phonewhat}

							</div>
						),
						style: {
							textAlign: "center",
						},
						sortable: false,
						maxWidth: '15%',
					});
				}
				if (key == 'status') {
					columns.push({
						name: <b>Status</b>,
						id: "status",
						accessor: (str) => "status",
						cell: (row, index) => (
							<div>
								{row.status == '1' ?
									<Badge color="success"> Active</Badge>
									:
									<Badge color="warning">Inactive</Badge>
								}



							</div>
						),
						style: {
							textAlign: "center",
						},
						sortable: false,
						maxWidth: '5%',
					});
				}



			}
			else if (page == 'cardlist') {

				if (key != 'status' && key != 'img') {
					//alert(key);
					columns.push({
						name: <b>{Capitalize(key.toString())}</b>,
						header: <b>{Capitalize(key.toString())}</b>,
						selector: key,
						Cell: editable,
						style: {
							textAlign: align,
						},
						sortable: true,
						maxWidth: width,
					});
				}
				if (key == 'img') {
					columns.push({
						name: <b>Image</b>,
						id: "status",
						accessor: (str) => "status",
						cell: (row, index) => (
							<div>
								{row.img != '' ?
									<img src={(row.img)} style={{ width: '50px', borderRadius: '50px' }} />
									:
									<img src="https://api.lobsmartcard.me/Api/Lobsmart/images/default.jpg" style={{ width: '50px' }} />
								}



							</div>
						),
						style: {
							textAlign: "center",
						},
						sortable: false,
						maxWidth: '5%',
					});
				}
				{/*if (key == 'uniqueid') {
					columns.push({
						name: <b>#</b>,
						id: "status",
						accessor: (str) => "status",
						cell: (row, index) => (
							<div>

								<Badge color="success"> {row.uniqueid}</Badge>




							</div>
						),
						style: {
							textAlign: "center",
						},
						sortable: false,
						maxWidth: '5%',
					});
				}*/}
				if (key == 'phonewhat') {
					columns.push({
						name: <b>WhatsApp No</b>,
						id: "status",
						accessor: (str) => "status",
						cell: (row, index) => (
							<div>
								{row.phonewhat}

							</div>
						),
						style: {
							textAlign: "center",
						},
						sortable: false,
						maxWidth: '15%',
					});
				}
				if (key == 'status') {
					columns.push({
						name: <b>Status</b>,
						id: "status",
						accessor: (str) => "status",
						cell: (row, index) => (
							<div>
								{row.status == '1' ?
									<Badge color="success"> Active</Badge>
									:
									<Badge color="warning">Inactive</Badge>
								}



							</div>
						),
						style: {
							textAlign: "center",
						},
						sortable: false,
						maxWidth: '5%',
					});
				}



			}
			else if (page == 'orderlist') {

				if (key != 'id' && key != 'status') {
					//alert(key);
					columns.push({
						name: <b>{Capitalize(key.toString())}</b>,
						header: <b>{Capitalize(key.toString())}</b>,
						selector: key,
						Cell: editable,
						style: {
							textAlign: align,
						},
						sortable: true,
						maxWidth: width,
					});
				}

				if (key == 'status') {
					columns.push({
						name: <b>Status</b>,
						id: "status",
						accessor: (str) => "status",
						cell: (row, index) => (
							<div>
								{row.status == '1' ?
									<Badge color="success"> Pending</Badge>
									: row.status == '2' ?
										<Badge color="warning">Processing</Badge>
										: row.status == '3' ?
											<Badge color="warning">Completed</Badge>
											:
											<Badge color="warning">Cancelled</Badge>
								}



							</div>
						),
						style: {
							textAlign: "center",
						},
						sortable: false,
						maxWidth: '5%',
					});
				}



			}

			else if (page == 'careerlist') {

				if (key != 'status') {
					//alert(key);
					columns.push({
						name: <b>{Capitalize(key.toString())}</b>,
						header: <b>{Capitalize(key.toString())}</b>,
						selector: key,
						Cell: editable,
						style: {
							textAlign: align,
						},
						sortable: true,
						maxWidth: width,
					});
				}
				if (key == 'status') {
					columns.push({
						name: <b>Status</b>,
						id: "status",
						accessor: (str) => "status",
						cell: (row, index) => (
							<div>
								{row.status == '1' ?
									<Badge color="success"> Active</Badge>
									:
									<Badge color="warning">Inactive</Badge>
								}



							</div>
						),
						style: {
							textAlign: "center",
						},
						sortable: false,
						maxWidth: '5%',
					});
				}



			}

			else {
				columns.push({
					name: <b>{Capitalize(key.toString())}</b>,
					header: <b>{Capitalize(key.toString())}</b>,
					selector: key,
					Cell: editable,
					style: {
						textAlign: align,
					},
					sortable: true,
					maxWidth: width,
				});
			}


		}
		i++;
	}

	if (multiSelectOption === true) {

	} else {


		if (page == 'careerlist') {



			/*columns.push({
				name: <b>Action</b>,
				id: "delete",
				accessor: (str) => "delete",
				cell: (row, index) => (
					<div>
						<a href={`${editurl}${row.id}`}><span>
							<i
		
								className="fa fa-eye"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "rgb(40, 167, 69)",
								}}
							></i>
		
						</span></a>
						<span onClick={() => handleDelete(row.Id)}>
							<i
								className="fa fa-trash"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
								}}
							></i>
							</span>
		
		
					</div>
				),
				style: {
					textAlign: "center",
				},
				sortable: false,
				maxWidth: '5%',
			});*/

		}


	}
	const handledatatable = (index, type, pagename, value) => {
		if (pagename == "card") {

			if (type == 'delete') {
				if (window.confirm("Are you sure you wish to delete this Record?")) {

					fetch("https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=card&id=" + value + "&viewtype=" + type)
						.then((res) => res.json())
						.then(
							(result) => {
								//console.log('yessssssss', result);
								const id = result[0]['id'];
								const message = result[0]['message'];
								if (id != undefined && message == 'success') {
									toast.success("Successfully Deleted.. !");

									setTimeout(() => {

										window.location.reload();
									}, 1000);
									//setData(myData);

								}
								else {
									const errormsg = result[0]['errormsg'];
									toast.error(errormsg);

								}


								//console.log(result);
							},
							(error) => {
								//console.log('no');
								//console.log(error);
								toast.error("Try again..Data not update..");

							}
						);


				}






			}

		}
		if (pagename == "cutomer") {

			if (type == 'delete') {
				if (window.confirm("Are you sure you wish to delete this Customer?")) {

					fetch("https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=lob_addprofile&uniqueid=" + value + "&type=" + type)
						.then((res) => res.json())
						.then(
							(result) => {
								//console.log('yessssssss', result);
								const id = result[0]['id'];
								const message = result[0]['message'];
								if (id != undefined && message == 'success') {
									toast.success("Successfully Deleted.. !");

									setTimeout(() => {

										window.location.reload();
									}, 1000);
									//setData(myData);

								}
								else {
									const errormsg = result[0]['errormsg'];
									toast.error(errormsg);

								}


								//console.log(result);
							},
							(error) => {
								//console.log('no');
								//console.log(error);
								toast.error("Try again..Data not update..");

							}
						);


				}






			}
			else if (type == 'statusupdate') {
				let res = "Inactive";
				if (value == '1') {
					res = "Active";
				}


				if (window.confirm("Are you sure you wish to " + res + " this Customer?")) {

					fetch("https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=lob_addprofile&uniqueid=" + index + "&type=" + type + "&status=" + value)
						.then((res) => res.json())
						.then(
							(result) => {
								//console.log('yessssssss', result);
								const id = result[0]['id'];
								const message = result[0]['message'];
								if (id != undefined && message == 'success') {
									toast.success("Successfully Updated.. !");

									setTimeout(() => {

										window.location.reload();
									}, 1000);
									//setData(myData);

								}
								else {
									const errormsg = result[0]['errormsg'];
									toast.error(errormsg);

								}


								//console.log(result);
							},
							(error) => {
								//console.log('no');
								//console.log(error);
								toast.error("Try again..Data not update..");

							}
						);




				}

				return false;

			}
		}
		if (pagename == "career") {

			if (type == 'delete') {
				if (window.confirm("Are you sure you wish to delete this Record?")) {

					fetch("https://www.laabamone.com/appoint_api.php?eventtype=getcareerlist&id=" + index + "&type=" + type)
						.then((res) => res.json())
						.then(
							(result) => {
								//console.log('yessssssss', result);
								const id = result[0]['id'];
								const message = result[0]['message'];
								if (id != undefined && message == 'success') {
									toast.success("Successfully Deleted.. !");

									setTimeout(() => {

										window.location.reload();
									}, 1000);
									//setData(myData);

								}
								else {
									const errormsg = result[0]['errormsg'];
									toast.error(errormsg);

								}


								//console.log(result);
							},
							(error) => {
								//console.log('no');
								//console.log(error);
								toast.error("Try again..Data not update..");

							}
						);


				}






			}
			else if (type == 'statusupdate') {
				let res = "Inactive";
				if (value == '1') {
					res = "Active";
				}


				if (window.confirm("Are you sure you wish to " + res + " this Record?")) {

					fetch("https://www.laabamone.com/appoint_api.php?eventtype=getcareerlist&id=" + index + "&type=" + type + "&status=" + value)
						.then((res) => res.json())
						.then(
							(result) => {
								//console.log('yessssssss', result);
								const id = result[0]['id'];
								const message = result[0]['message'];
								if (id != undefined && message == 'success') {
									toast.success("Successfully Updated.. !");

									setTimeout(() => {

										window.location.reload();
									}, 1000);
									//setData(myData);

								}
								else {
									const errormsg = result[0]['errormsg'];
									toast.error(errormsg);

								}


								//console.log(result);
							},
							(error) => {
								//console.log('no');
								//console.log(error);
								toast.error("Try again..Data not update..");

							}
						);




				}

				return false;

			}
		}
	}
	const getRow = (a) => {
		console.log(a, '----');
		const slectedcnt = a.selectedCount;
		if (slectedcnt > 0) {
			setselectedcheckbox('');
			const sel = a.allSelected;
			let inc = '';
			let result = '';
			for (inc = 0; inc < slectedcnt; inc++) {
				//console.log('inc', inc);
				if (a.selectedRows[inc].uniqueid != undefined) {
					const val = a.selectedRows[inc].uniqueid

					console.log('val', val);
					console.log('result', result);
					if (result != '') {
						result += ',' + val;
					}
					else {
						result = val;
					}
					console.log('result', result);

				}
				const res = result;
				setselectedcheckbox(res);
			}

		}
		else {
			setselectedcheckbox('');
		}

	}
	const Doaction = (type, action, value) => {
		let editurl = '';
		let viewurl = '';
		let pagename = '';
		if (type == 'customerlist') {
			editurl = "/customeredit/";
			viewurl = "/customerview/";
			pagename = "cutomer";
		}
		if (type == 'careerlist') {
			editurl = "/careeredit/";
			viewurl = "/careerview/";
			pagename = "career";
		}

		console.log('action', action)
		if (selectedcheckbox != '' && selectedcheckbox != null && selectedcheckbox != undefined || action == 'search' || action == 'download') {
			if (action == 'delete') {
				console.log(selectedcheckbox, 'selectedcheckbox');
				handledatatable(selectedcheckbox, 'delete', pagename);
			}

			if (action == 'edit') {
				console.log('editsssssss', selectedcheckbox);
				const res = selectedcheckbox.search(",");
				console.log(res, 'ressssss');
				if (res > 0) {
					toast.error("Select any One record to " + action);

				}
				else {
					window.location.href = editurl + '' + selectedcheckbox;
				}
			}
			if (action == 'view') {
				if (selectedcheckbox[1] != '' && selectedcheckbox[1] != undefined && selectedcheckbox[1] != null) {
					toast.error("Select any One record to " + action)
				}
				else {
					window.location.href = viewurl + '' + selectedcheckbox[0];
				}

			}
			if (action == 'status') {
				handledatatable(selectedcheckbox, 'statusupdate', pagename, value);

			}

			if (action == 'search') {
				if (value == '') {
					setData(myData);
				}
				else {
					console.log('--', value);
					let matches = [];
					//console.log(ninjas.filter((row) => row.email.toLowerCase().indexOf(q) > -1));
					if (type == 'subscriplist') {
						matches = data.filter(
							(row) =>
							(row.email.toLowerCase().indexOf(value) > -  1 || row.name.toLowerCase().indexOf(value) > -1
							));
					}
					if (type == 'careerlist') {
						matches = data.filter(
							(row) =>
							(row.email.toLowerCase().indexOf(value) > -  1 || row.state.toLowerCase().indexOf(value) > -1 || row.city.toLowerCase().indexOf(value) > -1 || row.fullname.toLowerCase().indexOf(value) > -1 || row.mobile.indexOf(value) > -1
							));
					}
					if (type == 'customerlist') {
						matches = data.filter(
							(row) =>
							(row.email.toLowerCase().indexOf(value) > -  1 || row.state.toLowerCase().indexOf(value) > -1 || row.city.toLowerCase().indexOf(value) > -1 || row.name.toLowerCase().indexOf(value) > -1 || row.lname.toLowerCase().indexOf(value) > -1 || row.mobile.indexOf(value) > -1
							));
					}
					console.log('matches', matches);
					setData(matches);
				}

			}
		}
		else {
			toast.error("Select any checkbox to " + action)
		}


	}
	return (
		<div>
			<Fragment>
				<DataTable
					data={data}
					columns={columns}
					//className={myClass}
					pagination={pagination}
					//striped={true}
					//center={true}
					//sortIcon={<SortIcon />}
					selectableRows
					selectableRowsHighlight
					onSelectedRowsChange={getRow}
					//	selectableRowsComponentProps={() => getRow()}
					subHeader
					subHeaderAlign="right"
					subHeaderComponent={
						<Stack direction="row" alignItems="center" spacing={1} fullWidth>
							{page != 'subscriplist' && page != 'orderlist' &&
								<><Button variant="outlined" onClick={() => Doaction(page, 'status', '1')}>Active</Button>
									<Button variant="outlined" onClick={() => Doaction(page, 'status', '0')} color="error" sx={{ width: '150px' }} >Inactive</Button>
								</>
							}

							{/*<IconButton color="danger" onClick={() => Doaction(page, 'delete')} aria-label="delete" sx={{ borderRadius: 1, border: '1px solid #757575' }} size="small" >
								<DeleteIcon fontSize="small" />
						</IconButton>*/}
							{page != 'subscriplist' && page != 'orderlist' &&
								<>{/*<IconButton color="primary" onClick={() => Doaction(page, 'edit')} aria-label="edit" size="small" sx={{ borderRadius: 1, border: '1px solid #757575' }}>
									<EditIcon fontSize="small" />
						</IconButton>*/}
									{/*<IconButton color="secondary" onClick={() => Doaction(page, 'view')} aria-label="view" size="small" sx={{ borderRadius: 1, border: '1px solid #757575' }}>
										<VisibilityIcon fontSize="small" />
						</IconButton>*/}
								</>
							}
							<CSVLink style={{ borderRadius: 3, border: '1px solid #757575', padding: '2.6px 5px', textAlign: 'center' }} data={data}><DownloadIcon fontSize="small" /></CSVLink>


							<input style={{ border: '1px solid #757575', borderRadius: 5 }}
								onKeyUp={(e) => Doaction(page, 'search', e.target.value)}
								className="form-control"
								type="search"
								placeholder=" Search.."
							/>

						</Stack>
					}
					highlightOnHover
				/>

				<ToastContainer />
			</Fragment>
		</div>
	);
};

export default Datatable;
