import React, { Fragment, useState, useEffect } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Card, CardBody, CardHeader, Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropzone from "../../common/dropzone";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsCookie from 'js-cookie';
import Moment from 'moment';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
//import Moment from 'moment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
//import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
//import { useRouter } from 'next/router';
const TabsetUser = () => {


  const { jobid } = useParams();
  console.log('jobid', jobid)
  /*const router = useRouter()
  const linkk = router.asPath;
  const ss = linkk.split('=');*/
  const idd = '';
  const [startDate, setStartDate] = useState(new Date());
  console.log('queryrouer4', idd);
  const [getuser, setUser] = useState([]);
  const [employer, setEmp] = useState([]);
  const [helper, setHelper] = useState([]);
  const [gifbutto, setGifbutt] = useState('');
  const [country, setCountry] = useState([]);
  const [JobPostTittle, changeJobPostTittle] = useState([]);


  const [setjobscope, changejobscope] = useState([false, false, false, false, false, false, false, false]);

  const [jobscope1, changejobscope1] = React.useState(false);
  const [jobscope2, changejobscope2] = React.useState(false);
  const [jobscope3, changejobscope3] = React.useState(false);
  const [jobscope4, changejobscope4] = React.useState(false);
  const [jobscope5, changejobscope5] = React.useState(false);
  const [jobscope6, changejobscope6] = React.useState(false);
  const [jobscope7, changejobscope7] = React.useState(false);
  const [jobscope8, changejobscope8] = React.useState(false);

  const [Elder, changeElder] = useState([]);
  const [Adult, changeAdult] = useState([]);
  const [Teen, changeTeen] = useState([]);
  const [Child, changeChild] = useState([]);
  const [Baby, changeBaby] = useState([]);


  const [addskill, setaddskill] = useState([]);
  const [postdetails, setpostform] = useState([]);


  const [InputDietaryPreference, setInputDietaryPreference] = useState([]);
  const [InputLanguage1, setInputLanguage1] = React.useState(false);
  const [InputLanguage2, setInputLanguage2] = React.useState(false);
  const [InputLanguage3, setInputLanguage3] = React.useState(false);
  const [InputLanguage4, setInputLanguage4] = React.useState(false);
  const [InputLanguage5, setInputLanguage5] = React.useState(false);
  const [InputLanguage6, setInputLanguage6] = React.useState(false);

  const [Inputcountry1, setInputcountry1] = React.useState(false);
  const [Inputcountry2, setInputcountry2] = React.useState(false);
  const [Inputcountry3, setInputcountry3] = React.useState(false);
  const [Inputcountry4, setInputcountry4] = React.useState(false);
  const [Inputcountry5, setInputcountry5] = React.useState(false);
  const [Inputcountry6, setInputcountry6] = React.useState(false);
  const [Inputcountry7, setInputcountry7] = React.useState(false);
  const [Inputcountry8, setInputcountry8] = React.useState(false);
  const [Inputcountry9, setInputcountry9] = React.useState(false);

  const [cooking1, setcooking1] = React.useState(false);
  const [cooking2, setcooking2] = React.useState(false);
  const [cooking3, setcooking3] = React.useState(false);
  const [cooking4, setcooking4] = React.useState(false);
  const [cooking5, setcooking5] = React.useState(false);
  const [cooking6, setcooking6] = React.useState(false);

  const [religion1, setreligion1] = React.useState(false);
  const [religion2, setreligion2] = React.useState(false);
  const [religion3, setreligion3] = React.useState(false);
  const [religion4, setreligion4] = React.useState(false);



  const [AdditionalRequirements, changeAdditionalRequirements] = useState([]);
  const [InputSalary, setInputSalary] = useState([]);
  const [InputOffDay, setInputOffDay] = useState([]);
  const [InputExper, setInputExper] = useState([]);
  const [InputAccommodation1, setInputAccommodation1] = useState([]);
  const [InputAccommodation2, setInputAccommodation2] = useState([]);
  const [InputAccommodation3, setInputAccommodation3] = useState([]);
  const [InputAccommodation4, setInputAccommodation4] = useState([]);

  const [Inputage, setInputage] = useState([]);
  const [Inputhousing, setInputhousing] = useState([]);
  const [startdatevalue, setstartdateValue] = React.useState(null);
  const [err, setErr] = useState([]);
  const [suc, setSuc] = useState([]);


  const [inputListpet, setInputListpet] = useState([{ pet: "" }]);

  const handleInputChangepet = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListpet];
    list[index][name] = value;
    setInputListpet(list);
  };

  // handle click event of the Remove button
  const handleRemoveClickpet = index => {

    const list = [...inputListpet];
    list.splice(index, 1);
    setInputListpet(list);
  };
  const handleAddClickpet = () => {
    setInputListpet([...inputListpet, { pet: "" }]);
  };

  useEffect(() => {




    const loadUsers = async () => {
      var ciphertext = jsCookie.get('USERID');

      if (ciphertext != '' && ciphertext != null && ciphertext != undefined) {
        // var bytes = CryptoJS.AES.decrypt(ciphertext, 'my-secret-key@123');
        //const emp_id = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        const response = await axios.get("https://api.laabamone.com/api/v1/GetUserByID?ID=" + ciphertext);
        console.log('userdetails', response.data);
        setUser(response.data);
      }

    }
    const loadCountry = async () => {
      const responsec = await axios.get("https://api.laabamone.com/api/v1/Masters/Country");
      setCountry(responsec.data.$values);
      console.log('data', responsec.data.$values);

    }
    const loadpostform = async () => {

      const responsec = await axios.get("https://api.laabamone.com/api/v1/Masters/GetJobPostbyID?ID=" + jobid);
      setpostform(responsec.data);
      setaddskill(responsec.data.AdditionalBenefits);
      changeElder(responsec.data.Elder);
      changeAdult(responsec.data.Adult);
      changeTeen(responsec.data.Teen);
      changeChild(responsec.data.Child);
      changeBaby(responsec.data.Baby);
      changeJobPostTittle(responsec.data.PostTitle);


      const iss = responsec.data.SpokenLanguage.split(',');
      setInputLanguage1(iss[0] == 'English' ? true : false)
      setInputLanguage2(iss[1] == 'Mandarin' ? true : false)
      setInputLanguage3(iss[2] == 'Bahasa' ? true : false)
      setInputLanguage4(iss[3] == 'Tamil' ? true : false)
      setInputLanguage5(iss[4] == 'Hokkien' ? true : false)
      setInputLanguage6(iss[5] == 'Cantonese' ? true : false)

      const ass = responsec.data.Accomodation.split(',');
      setInputAccommodation1(ass[0] == '1' ? true : false)
      setInputAccommodation2(ass[1] == '1' ? true : false)
      setInputAccommodation3(ass[2] == '1' ? true : false)
      setInputAccommodation4(ass[3] == '1' ? true : false)

      const rss = responsec.data.Religion.split(',');
      setreligion1(rss[0] == '1' ? true : false)
      setreligion2(rss[1] == '1' ? true : false)
      setreligion3(rss[2] == '1' ? true : false)
      setreligion4(rss[3] == '1' ? true : false)


      const css = responsec.data.CookingSkill.split(',');
      setcooking1(css[0] == '1' ? true : false)
      setcooking2(css[1] == '1' ? true : false)
      setcooking3(css[2] == '1' ? true : false)
      setcooking4(css[3] == '1' ? true : false)
      setcooking5(css[4] == '1' ? true : false)
      setcooking6(css[5] == '1' ? true : false)

      const ss = responsec.data.JobScope.split(',');
      changejobscope1(ss[0] == '1' ? true : false)
      changejobscope2(ss[1] == '1' ? true : false)
      changejobscope3(ss[2] == '1' ? true : false)
      changejobscope4(ss[3] == '1' ? true : false)
      changejobscope5(ss[4] == '1' ? true : false)
      changejobscope6(ss[5] == '1' ? true : false)
      changejobscope7(ss[6] == '1' ? true : false)
      changejobscope8(ss[7] == '1' ? true : false)



      //changejobscope1(!jobscope1);
      setInputhousing(responsec.data.Housing);
      setstartdateValue(responsec.data.WorkStartDate);
      setInputage(responsec.data.AgeRange);
      setInputExper(responsec.data.YearofExperience);
      setInputDietaryPreference(responsec.data.DietPreference);
      setInputSalary(responsec.data.Salary);
      setInputOffDay(responsec.data.OffDay);
      changeAdditionalRequirements(responsec.data.AdditionalRequirements)

      console.log('dddd', responsec.data);
      console.log('postform', responsec.data);

    }
    const loadEmp = async () => {
      const emp_id = jsCookie.get('empid');
      const response2 = await axios.get("https://api.laabamone.com/api/v1/Masters/GetEmployerListByUserID?ID=" + emp_id);
      console.log('empdetails', response2.data);
      setEmp(response2.data);

    }
    if (jobid != '' && jobid != null && jobid != undefined) {
      loadpostform();
    }

    loadEmp();
    loadUsers();
    loadCountry();

  }, [])
  const Savepet = async (e) => {

    console.log('e', e);

    console.log('inputListpet', inputListpet);
    let p = '';
    for (p = '0'; p < inputListpet.length; p++) {


      var passdata = {
        "PetId": 0,
        "TypeOfPet": inputListpet[p].typeofpet,
        "Number": inputListpet[p].petcount,
        "JobpostId": e
      };

      const responseemp = await fetch("https://api.laabamone.com/api/v1/Masters/CreatePet", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(passdata),

      });
      const datapet = await responseemp.json();
      console.log('datapet', datapet);


      const res = datapet.ResponseCode;
      if (res == 'Created') {
        // setGifbutt(''); 
        // setSuc('Successfully Updated..');      
      }
    }
    setGifbutt('');
    setSuc('Successfully Updated..');

  }
  const registerEmpPost = async event => {
    event.preventDefault();
    // setGifbutt('/assets/images/loading.gif');   










    let StartDate2 = startdatevalue;
    let StartDate1 = Moment(`${StartDate2}`).format('DD/MM/YYYY');
    let sdate = StartDate1.split("/");
    let StartDate11 = sdate[2] + '-' + sdate[1] + '-' + sdate[0];

    let js1 = jobscope1 ? '1' : '0';
    let js2 = jobscope2 ? '1' : '0';
    let js3 = jobscope3 ? '1' : '0';
    let js4 = jobscope4 ? '1' : '0';
    let js5 = jobscope5 ? '1' : '0';
    let js6 = jobscope6 ? '1' : '0';
    let js7 = jobscope7 ? '1' : '0';
    let js8 = jobscope8 ? '1' : '0';

    let acco1 = InputAccommodation1 ? '0' : '1';
    let acco2 = InputAccommodation2 ? '0' : '1';
    let acco3 = InputAccommodation3 ? '0' : '1';
    let acco4 = InputAccommodation4 ? '0' : '1';


    let sl1 = InputLanguage1 ? 'English' : '0';
    let sl2 = InputLanguage2 ? 'Mandarin' : '0';
    let sl3 = InputLanguage3 ? 'Bahasa' : '0';
    let sl4 = InputLanguage4 ? 'Tamil' : '0';
    let sl5 = InputLanguage5 ? 'Hokkien' : '0';
    let sl6 = InputLanguage6 ? 'Cantonese' : '0';

    let cook1 = cooking1 ? '1' : '0';
    let cook2 = cooking2 ? '1' : '0';
    let cook3 = cooking3 ? '1' : '0';
    let cook4 = cooking4 ? '1' : '0';
    let cook5 = cooking5 ? '1' : '0';
    let cook6 = cooking6 ? '1' : '0';

    let rel1 = religion1 ? '1' : '0';
    let rel2 = religion2 ? '1' : '0';
    let rel3 = religion3 ? '1' : '0';
    let rel4 = religion4 ? '1' : '0';
    let rel5 = cooking5 ? '1' : '0';
    let rel6 = cooking6 ? '1' : '0';

    let eld = Elder;
    let ad = Adult;
    let tee = Teen;
    let chi = Child;
    let bab = Baby;

    let s = '';
    let str = '';

    let ico1 = Inputcountry1 ? '1' : '0';
    let ico2 = Inputcountry2 ? '1' : '0';
    let ico3 = Inputcountry3 ? '1' : '0';
    let ico4 = Inputcountry4 ? '1' : '0';
    let ico5 = Inputcountry5 ? '1' : '0';
    let ico6 = Inputcountry6 ? '1' : '0';
    let ico7 = Inputcountry7 ? '1' : '0';
    //let ico8=   Inputcountry8 ?  '1' :'0';


    let Jobsc = js1 + ',' + js2 + ',' + js3 + ',' + js4 + ',' + js5 + ',' + js6 + ',' + js7 + ',' + js8;
    let slang = sl1 + ',' + sl2 + ',' + sl3 + ',' + sl4 + ',' + sl5 + ',' + sl6;
    let acoomo = acco1 + ',' + acco2 + ',' + acco3 + ',' + acco4;
    let cooki = cook1 + ',' + cook2 + ',' + cook3 + ',' + cook4 + ',' + cook5 + ',' + sl6;
    let relg = rel1 + ',' + rel2 + ',' + rel3 + ',' + rel4;
    let count = ico1 + ',' + ico2 + ',' + ico3 + ',' + ico4 + ',' + ico5 + ',' + ico6 + ',' + ico7;





    console.log('hobsceope', Jobsc)
    console.log('spokenlan', slang)
    console.log('accom', acoomo)
    console.log('cooking', cooki)
    const JobPostId11 = jsCookie.get('JobPostId');

    setGifbutt('');
    if (JobPostId11 != '' && JobPostId11 != null && JobPostId11 != undefined) {

      //var bytes = CryptoJS.AES.decrypt(JobPostId, 'my-secret-key@123');
      // const JobPostId11 = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));          

      var passdata = {
        "JobPostId": JobPostId11,
        "EmployerId": postdetails.EmployerId,
        "PostTitle": JobPostTittle,
        "JobScope": Jobsc,
        "CookingSkill": cooki,
        "AdditionalRequirements": AdditionalRequirements,
        "Elder": eld,
        "Adult": ad,
        "Teen": tee,
        "Child": chi,
        "Baby": bab,
        "Housing": Inputhousing,
        "WorkStartDate": StartDate11,
        "AgeRange": Inputage,
        "YearofExperience": InputExper,
        "Nationality": count,
        "Religion": relg,
        "DietPreference": InputDietaryPreference,
        "SpokenLanguage": slang,
        "Salary": InputSalary,
        "OffDay": InputOffDay,
        "Accomodation": "string",
        "AdditionalBenefits": addskill
      };


      const responseemp = await fetch("https://api.laabamone.com/api/v1/Masters/UpdateJobPost", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(passdata),

      });
      const dataemp = await responseemp.json();
      console.log('dataed', dataemp);


      const res = dataemp.ResponseCode;
      if (res == 'Created') {

        //setGifbutt('/assets/images/loading.gif'); 
        setErr('');
        setSuc('Successfully Updated..');
        setGifbutt('');
        // window.location.href = "/empmyaccount";   

      }
      else {
        setGifbutt('');
        const errmessage = dataemp.title;
        setErr(errmessage);
        setSuc('');
      }



    }
    else {


      var passdata = {
        "JobPostId": 0,
        "EmployerId": employer.EmployerId,
        "PostTitle": JobPostTittle,
        "JobScope": Jobsc,
        "CookingSkill": cooki,
        "AdditionalRequirements": AdditionalRequirements,
        "Elder": eld,
        "Adult": ad,
        "Teen": tee,
        "Child": chi,
        "Baby": bab,
        "Housing": Inputhousing,
        "WorkStartDate": StartDate11,
        "AgeRange": Inputage,
        "YearofExperience": InputExper,
        "Nationality": 0,
        "Religion": relg,
        "DietPreference": InputDietaryPreference,
        "SpokenLanguage": slang,
        "Salary": InputSalary,
        "OffDay": InputOffDay,
        "Accomodation": acoomo,
        "AdditionalBenefits": addskill
      };

      const response = await fetch("https://api.laabamone.com/api/v1/Masters/CreateJobPost", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(passdata),

      });
      const data = await response.json();
      console.log('response', data);
      const res = data.ResponseCode;
      if (res == 'Created') {

        let idd = data.Item.JobPostId;
        setErr('');
        Savepet(idd);

      }
      else {
        setGifbutt('');
        const errmessage = data.title;
        console.log('responseer', errmessage);
        setErr(errmessage);
      }

    }
  }

  console.log('jobscope', jobscope1);


  return (
    <Fragment>


      <Row>Loading</Row>


    </Fragment>
  );

};

export default TabsetUser;
