import React, { Fragment, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, Alert } from '@mui/material';
import axios from 'axios';
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Row, ModalHeader, ModalBody, Form, FormGroup, Label, ModalFooter } from "reactstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Breadcrumb from "../../common/breadcrumb";

export default function Serviceview() {
    const { cusid, productid } = useParams();
    const [createObjectURL_pimage, setCreateObjectURL_pimage] = useState(null);
    const [pname, setpname] = useState('');
    const [pimage, setpImage] = useState([]);
    const [logcompid, setlogcompid] = useState('');
    const [Errorval, setErrorval] = useState('');
    const [pdescription, setpdescription] = useState('');
    const [plink, setplink] = useState('');
    const [sizeerr, setsizeerr] = useState('');
    const [proddata, setproddata] = useState('');
    const [Buttonloader, setButtonloader] = useState('1');
    const [profiledata, setprofiledata] = useState('');
    const [pnameerror, setpnameerror] = useState('');


    useEffect(() => {
        // Perform localStorage action
        const getProfile1 = async (a) => {

            const response = await fetch('https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=lob_Getprofile&profileid=' + a);
            const json = await response.json();
            console.log(json);
            setprofiledata(json);




        }

        const getProfile = async (a, b) => {

            const response = await fetch('https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=lob_Getservice&profileid=' + a + '&productid=' + b);
            const json = await response.json();
            console.log(json);
            setproddata(json);
            setpname(json[0].name);
            setpdescription(json[0].message);
            setplink(json[0].link);
            setCreateObjectURL_pimage(json[0].image)
        }





        if (productid != null && productid != undefined && productid != '') {
            getProfile(cusid, productid);
            setlogcompid(productid);

        }
        getProfile1(cusid);





    }, [productid, cusid])


    const SaveProductImage = (e) => {
        const SIZE = 20 * 1024;
        if (e.target.files && e.target.files[0]) {

            if (e.target.files[0].size > SIZE) {

                setsizeerr('Product Image Size greater than 20kb');
                toast.error("Product Image Size greater than 20kb");
                //console.log("file size greater than " + SIZE);
            } else {
                setsizeerr('');
                const i = e.target.files[0];
                setpImage(i);
                console.log(i);
                setCreateObjectURL_pimage(URL.createObjectURL(i));
            }

            // registerDocument1('1', i);
        }

    }
    const registerDocument1 = async (a) => {
        // event.preventDefault();


        //console.log(image.name);
        // console.log(simage.name);
        //  console.log(pimage.name);



        if (pimage.name != '' && pimage.name != undefined && pimage.name != null) {
            setButtonloader('2');
            let url = "https://api.lobsmartcard.me/Api/Lobsmart/api.php"
            const formData = new FormData();
            formData.append('eventtype', 'lob_addprofile_image');
            formData.append('id', a);
            formData.append('simage', pimage);
            let config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            axios.post(url, formData, config)
                .then((response) => {

                    console.log('result', response);
                    setButtonloader('1');
                    //  toast.success("Updated Successfully...");
                    // window.location.href = "/customeredit/" + a;
                    // setActiveStep('2');

                })
                .catch((error) => {
                    toast.success("Error...");
                    setButtonloader('1');
                    console.log({ status: `upload failed ${error}` });
                })
        }

        toast.success("Added Successfully...");



    }
    const SaveProduct = () => {

        let str = '';
        let error = 0;
        if (pname == '' || pname == undefined || pname == null) {
            setpnameerror("Required");
            toast.error("Fill Requried Fields")
            error = 1;
        }

        if (error == 1) {
            setButtonloader('1');
            return false;
        }
        else {
            setpnameerror("");
            if (logcompid != null && logcompid != '' && logcompid != undefined) {
                str = '&productid=' + logcompid;
            }
            console.log('https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=lob_addprofile&type=addservice'
                + str + '&profileid=' + cusid + '&name=' + pname +
                '&message=' + pdescription +
                '&link=' + plink)
            fetch('https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=lob_addprofile&type=addservice'
                + str + '&profileid=' + cusid + '&name=' + pname +
                '&message=' + pdescription +
                '&link=' + plink
            )
                .then((res) => res.json())
                .then(
                    (result) => {
                        console.log('yessssssss');
                        const id = result[0]['id'];
                        const message = result[0]['message'];
                        if (id != undefined && message == 'success') {

                            setButtonloader(1);
                            if (productid != undefined) {

                            }
                            else {
                                setpname('');
                                setplink('');
                                setpdescription('');
                                setpImage('');
                            }

                            registerDocument1(id);
                            setErrorval('');


                        }
                        else {
                            const errormsg = result[0]['errormsg'];
                            toast.error(errormsg)
                            setErrorval(errormsg);
                            setButtonloader(1);
                        }


                        console.log(result);
                    },
                    (error) => {
                        console.log('no');
                        console.log(error);
                        toast.error("Try again..Data not update..");
                        setButtonloader(1);
                    }
                );
        }
    }
    if (profiledata.length > 0) {
        return (
            <Fragment>

                <Breadcrumb title="Service" parent="Customer Edit" link={`/customeredit/${profiledata[0].uniqueid}`} subparent="Service" />
                {/* <!-- Container-fluid starts--> */}
                <Container fluid={true}>
                    <Row>

                        <Col sm="12">
                            <Card >


                                <CardBody style={{ display: 'flex' }} >


                                    <Box fullWidth sx={{ padding: '20px', borderRadius: '6px', border: '1px solid #557191' }}>

                                        <div className="row col-md-12" >
                                            <div className="col-md-12 " style={{ borderBottom: '.5px solid #557191' }}>
                                                <h3>Service:</h3>
                                            </div>
                                            <div className="col-md-12 " style={{ paddingBottom: '20px' }}>

                                            </div>

                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>

                                                <TextField
                                                    value={pname}
                                                    error={!!pnameerror}
                                                    helperText={pnameerror}

                                                    onChange={(e) => { setpname(e.target.value) }}
                                                    fullWidth label="Service Name: *" variant="outlined">


                                                </TextField>
                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>

                                                <TextField
                                                    value={pdescription}
                                                    onChange={(e) => {
                                                        setpdescription(e.target.value)
                                                        // , setadd2error('') 
                                                    }}
                                                    fullWidth label="Description::" variant="outlined">


                                                </TextField>
                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>

                                                <TextField
                                                    value={plink}
                                                    onChange={(e) => {
                                                        setplink(e.target.value)
                                                        // , setadd2error('') 
                                                    }}
                                                    fullWidth label="Link" variant="outlined">


                                                </TextField>
                                            </div>
                                            <div className="row col-md-12" style={{ padding: '20px 20px' }}>
                                                <div className="col-md-12 " >
                                                    <h4>Service Image:</h4>
                                                </div>
                                                <div className="col-md-3 "></div>
                                                <div className="col-md-3 ">

                                                    <Box sx={{ border: '1px dashed #dbdbe3', padding: '55px' }}
                                                        variant="contained"
                                                        component="label"
                                                    >
                                                        <b style={{ fontSize: '25px' }}> +</b>
                                                        <input

                                                            onChange={(e) => SaveProductImage(e)}
                                                            type="file"
                                                            name="myImage"
                                                            accept="image/x-png,image/jpeg,image/jpg"
                                                            hidden
                                                        />
                                                    </Box>
                                                </div>
                                                <div className="col-md-6 ">
                                                    {createObjectURL_pimage != null && createObjectURL_pimage != '' && createObjectURL_pimage != undefined &&
                                                        <img src={createObjectURL_pimage} style={{ width: '150px', height: '150px' }} />
                                                    }

                                                </div>


                                            </div>
                                            {Buttonloader == '1' ?
                                                <button
                                                    className="btn btn-primary btn-square bootstrap-touchspin-down"
                                                    type="button"
                                                    onClick={() => SaveProduct()}
                                                >
                                                    Update
                                                </button>

                                                :
                                                <div className="btn btn-primary btn-square bootstrap-touchspin-down"
                                                >

                                                    <img src="/buttonload3.gif" style={{ height: '30px' }}></img>
                                                </div>
                                            }
                                        </div>

                                    </Box>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
            </Fragment>
        );
    }
    else {
        return (
            <>Loading.</>
        )
    }

}