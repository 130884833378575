import React, { Fragment, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, Alert } from '@mui/material';
import axios from 'axios';
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Row, ModalHeader, ModalBody, Form, FormGroup, Label, ModalFooter } from "reactstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Breadcrumb from "../../common/breadcrumb";

export default function Productview({ id }) {
    const { cusid, productid } = useParams();
    const [createObjectURL_pimage, setCreateObjectURL_pimage] = useState(null);
    const [pname, setpname] = useState('');
    const [pimage, setpImage] = useState([]);
    const [logcompid, setlogcompid] = useState('');
    const [Errorval, setErrorval] = useState('');
    const [pdescription, setpdescription] = useState('');
    const [plink, setplink] = useState('');
    const [sizeerr, setsizeerr] = useState('');
    const [proddata, setproddata] = useState('');
    const [Buttonloader, setButtonloader] = useState('1');
    const [profiledata, setprofiledata] = useState('');
    const [pnameerror, setpnameerror] = useState('');


    useEffect(() => {
        // Perform localStorage action
        const getProfile1 = async (a) => {

            const response = await axios.get("https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=lob_createorder&viewtype=list&id=" + a);
            console.log('order', response.data);


            setprofiledata(response.data);




        }






        if (cusid != null && cusid != undefined && cusid != '') {
            getProfile1(cusid);
        }
        getProfile1(cusid);





    }, [productid, cusid])


    const SaveProductImage = (e) => {
        const SIZE = 20 * 1024;
        if (e.target.files && e.target.files[0]) {

            if (e.target.files[0].size > SIZE) {

                setsizeerr('Product Image Size greater than 20kb');
                toast.error("Product Image Size greater than 20kb");
                //console.log("file size greater than " + SIZE);
            } else {
                setsizeerr('');
                const i = e.target.files[0];
                setpImage(i);
                console.log(i);
                setCreateObjectURL_pimage(URL.createObjectURL(i));
            }

            // registerDocument1('1', i);
        }

    }
    const registerDocument1 = async (a) => {
        // event.preventDefault();


        //console.log(image.name);
        // console.log(simage.name);
        //  console.log(pimage.name);



        if (pimage.name != '' && pimage.name != undefined && pimage.name != null) {
            setButtonloader('2');
            let url = "https://api.lobsmartcard.me/Api/Lobsmart/api.php"
            const formData = new FormData();
            formData.append('eventtype', 'lob_addprofile_image');
            formData.append('id', a);
            formData.append('pimage', pimage);
            let config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            axios.post(url, formData, config)
                .then((response) => {

                    console.log('result', response);
                    setButtonloader('1');
                    //  toast.success("Updated Successfully...");
                    // window.location.href = "/customeredit/" + a;
                    // setActiveStep('2');

                })
                .catch((error) => {
                    toast.success("Error...");
                    setButtonloader('1');
                    console.log({ status: `upload failed ${error}` });
                })
        }

        toast.success("Added Successfully...");



    }
    const SaveProduct = () => {

        let str = '';
        let error = 0;
        if (pname == '' || pname == undefined || pname == null) {
            setpnameerror("Required");
            toast.error("Fill Requried Fields")
            error = 1;
        }

        if (error == 1) {
            setButtonloader('1');
            return false;
        }
        else {
            setpnameerror("");
            if (logcompid != null && logcompid != '' && logcompid != undefined) {
                str = '&productid=' + logcompid;
            }
            console.log('https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=lob_addprofile&type=addproduct'
                + str + '&profileid=' + cusid + '&name=' + pname +
                '&message=' + pdescription +
                '&link=' + plink)
            fetch('https://api.lobsmartcard.me/Api/Lobsmart/api.php?eventtype=lob_addprofile&type=addproduct'
                + str + '&profileid=' + cusid + '&name=' + pname +
                '&message=' + pdescription +
                '&link=' + plink
            )
                .then((res) => res.json())
                .then(
                    (result) => {
                        console.log('yessssssss');
                        const id = result[0]['id'];
                        const message = result[0]['message'];
                        if (id != undefined && message == 'success') {
                            setButtonloader(1);
                            setpname('');
                            setplink('');
                            setpdescription('');
                            setpImage('');

                            registerDocument1(id);
                            setErrorval('');


                        }
                        else {
                            const errormsg = result[0]['errormsg'];
                            toast.error(errormsg)
                            setErrorval(errormsg);
                            setButtonloader(1);
                        }


                        console.log(result);
                    },
                    (error) => {
                        console.log('no');
                        console.log(error);
                        toast.error("Try again..Data not update..");
                        setButtonloader(1);
                    }
                );
        }
    }
    if (profiledata.length > 0) {
        return (
            <Fragment>

                <Breadcrumb title="Order" parent="Order" link={`/order}`} subparent="Order" />
                {/* <!-- Container-fluid starts--> */}
                <Container fluid={true}>
                    <Row>

                        <Col sm="12">
                            <Card >


                                <CardBody style={{ display: 'flex' }} fullWidth>


                                    <Box fullWidth sx={{ padding: '20px', borderRadius: '6px', border: '1px solid #557191' }}>

                                        <div className="row col-md-12" >
                                            <div className="col-md-12 " >
                                                <h3>Order ID: <b>{profiledata[0].orderid}</b></h3>
                                            </div>
                                            <div className="col-md-12 " style={{ borderBottom: '.5px solid #557191' }}>
                                                <h3>Date: <b>{profiledata[0].date}</b></h3>
                                            </div>
                                            <div className="col-md-8 " style={{ paddingBottom: '20px' }}>

                                            </div>
                                            <div className="col-md-4 " style={{ paddingBottom: '20px' }}>
                                                {profiledata[0].address[0].name} {profiledata[0].address[0].lname}
                                                <br /> {profiledata[0].address[0].add1} , {profiledata[0].address[0].add2}
                                                <br /> {profiledata[0].address[0].city}, {profiledata[0].address[0].state}-{profiledata[0].address[0].postcode}
                                            </div>

                                            <div className="col-md-12 " style={{ borderBottom: '.5px solid #557191' }}>
                                                <h3>Products</h3>
                                            </div>
                                            <div className="col-md-12 " style={{ borderBottom: '.5px solid #557191' }}>

                                                <table style={{ width: '100%' }}>
                                                    <tbody>


                                                        {profiledata[0].items.map((a, inc) =>


                                                        (
                                                            <tr style={{ width: '100%', padding: '20px' }}>
                                                                <td style={{ width: '10%' }}>    {inc + 1}</td>
                                                                <td style={{ width: '60%' }}>    {a.itemname}</td>
                                                                <td style={{ width: '30%', textAlign: 'right' }}>   RS.2000</td>
                                                            </tr>
                                                        ))}
                                                        <tr style={{ width: '100%', borderTop: '.5px solid #557191' }}>
                                                            <td style={{ width: '10%' }}> </td>
                                                            <td style={{ width: '60%', textAlign: 'right' }}> Subtotal</td>
                                                            <td style={{ width: '30%', textAlign: 'right' }}>   RS.{profiledata[0].subtotal}</td>

                                                        </tr>
                                                        <tr style={{ width: '100%' }}>
                                                            <td style={{ width: '10%' }}> </td>
                                                            <td style={{ width: '60%', textAlign: 'right' }}> Tax (10%)</td>
                                                            <td style={{ width: '30%', textAlign: 'right' }}>   RS.480</td>

                                                        </tr>
                                                        <tr style={{ width: '100%', borderTop: '.5px solid #557191' }}>
                                                            <td style={{ width: '10%' }}> </td>
                                                            <td style={{ width: '60%', textAlign: 'right' }}> Total</td>
                                                            <td style={{ width: '30%', textAlign: 'right' }}>   RS.{profiledata[0].total}</td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </Box>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
            </Fragment >
        );
    }
    else {
        return (
            <>Loading.</>
        )
    }

}