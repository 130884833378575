import React, { Fragment } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Card, CardBody, CardHeader, Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropzone from "../../common/dropzone";
const TabsetUser = () => {
	return (
		<Fragment>
		
		</Fragment>
	);
};

export default TabsetUser;
