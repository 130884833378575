import React, { Fragment, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import { useParams } from "react-router-dom";

import jsCookie from 'js-cookie';
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Row, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter } from "reactstrap";
import Breadcrumb from "../../common/breadcrumb";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";



//import nextBase64 from 'next-base64';
//var json_encode = require('json_encode');
export default function EditView() {
    jsCookie.set('pagename', 'career');
    const { cusid } = useParams();
    // console.log(cusid);
    const recaptchaRef = React.createRef();
    const [fileerror, setfileerror] = useState('');
    const [cname, setcname] = useState('');
    const [add1, setadd1] = useState();
    const [add2, setadd2] = useState();
    const [city, setcity] = useState();
    const [state, setstate] = useState();
    const [countryval, setcountryval] = useState('');
    const [postcode, setpostcode] = useState();
    const [user_mobile, setuser_mobile] = useState('');
    const [user_email, setuser_email] = useState('');
    const [Countrylist, setCountrylist] = useState([]);
    const [Errorval, setErrorval] = useState('');
    const [sucval, setsucc] = useState('');
    const [resumefile, setresumefile] = useState('');
    const [desc, setdesc] = useState('');
    const [careerlist, setcareerlist] = useState([]);
    const [status, setstatus] = useState('1');
    /* const handleSubmit = (event) => {
         event.preventDefault();
         // Execute the reCAPTCHA when the form is submitted
         recaptchaRef.current.execute();
     };*/
    function uploadFile(a) {
        console.log('resumefile', resumefile);
        let url = 'https://www.laabamone.com/appoint_api.php'
        const data = new FormData();
        data.append("eventtype", 'uploadresume');
        data.append("id", a);
        data.append("file", resumefile);
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        };
        axios.post(url, data)
            .then((response) => {
                // console.log('result', response);

            })
            .catch((error) => {
                //  console.log({ status: `upload failed ${error}` });
            })


    }
    const onReCAPTCHAChange = (captchaCode) => {
        // If the reCAPTCHA code is null or undefined indicating that
        // the reCAPTCHA was expired then return early
        if (!captchaCode) {
            return;
        }
        // Else reCAPTCHA was executed successfully so proceed with the 
        // alert
        //  alert(`Hey`);
        // Reset the reCAPTCHA so that it can be executed again if user 
        // submits another email.
        recaptchaRef.current.reset();
    }
    function checkfileext(w) {
        setresumefile(w.target.files[0])
        console.log("--+++---", w.target.files[0]);
        const file = w.target.files[0];
        const name = w.target.files[0].name;
        const t = name.split('.').pop();
        console.log("-----", t)
        // const base64Encoded = nextBase64.encode(w);
        /* let url = 'https://www.laabamone.com/appoint_api.php?eventtype=uploadresume&id=1'
         let formData = new FormData();
         formData.append("FileName", name);
         formData.append("file", w);
         let config = {
             headers: {
                 'Content-Type': 'multipart/form-data'
             },
         };
         axios.post(url, formData, config)
             .then((response) => {
                 console.log('result', response);
 
             })
             .catch((error) => {
                 console.log({ status: `upload failed ${error}` });
             })*/
        if (t == 'pdf' || t == 'doc' || t == 'docx') {
            setfileerror('');
        }
        else {
            setfileerror('accept only pdf,doc,dox files');
        }
        uploadFile();
    }
    useEffect(() => {
        // Perform localStorage action



        const getcareer = async (a) => {

            const response = await fetch('https://www.laabamone.com/appoint_api.php?eventtype=getcareerlist&id=' + cusid);
            const json = await response.json();
            // alert(json[0]['usercode']);
            setcareerlist(json);
            setcname(json[0]['fullname']);
            setuser_email(json[0]['email']);
            setuser_mobile(json[0]['mobile']);
            setadd1(json[0]['add1']);
            setadd2(json[0]['add2']);
            setcity(json[0]['city']);
            setstate(json[0]['state']);
            setpostcode(json[0]['postcode']);
            setcountryval(json[0]['countryval']);
            setdesc(json[0]['description']);
            setstatus(json[0]['status'])




        }
        const getCountry = async (a) => {

            const response = await fetch('https://www.laabamone.com/appoint_api.php?eventtype=countylist');
            const json = await response.json();
            // alert(json[0]['usercode']);
            setcountryval(json[0]['usercode']);
            console.log(json);
            setCountrylist(json);




        }


        getCountry();
        getcareer();





    }, [cusid])
    const onsubmit = (event) => {
        event.preventDefault();
        // function onsubmit() {


        /*  console.log('https://www.laabamone.com/appoint_api.php?eventtype=addcareerform&fullname='
              + cname +
              '&country=' + countryval +
              '&state=' + state +
              '&city=' + city +
              '&description=' + desc +
              '&postcode=' + postcode +
              '&add1=' + add1 +
              '&add2=' + add2 +
              '&email=' + user_email +
              '&mobile=' + user_mobile);*/

        fetch('https://www.laabamone.com/appoint_api.php?eventtype=addcareerform&id=' + cusid + 'fullname='
            + cname +
            '&country=' + countryval +
            '&state=' + state +
            '&city=' + city +
            '&description=' + desc +
            '&postcode=' + postcode +
            '&add1=' + add1 +
            '&add2=' + add2 +
            '&email=' + user_email +
            '&mobile=' + user_mobile +
            '&status=' + status

        )
            .then((res) => res.json())
            .then(
                (result) => {
                    console.log('yes');


                    const id = result[0]['id'];

                    const message = result[0]['message'];

                    if (id != undefined && message == 'success') {
                        // setErrorval('');
                        //  setsucc('Successfully Added')
                        toast.success("Successfully Added");
                        window.scrollTo(0, 250);
                        /* setcname('');
                         setcountryval('');
                         setstate('');
                         setcity('');
                         setpostcode('');
                         setadd1('');
                         setadd2('');
                         setuser_email('');
                         setuser_mobile('');*/
                        uploadFile(id)
                        //  window.location.href = "/register";
                    }
                    else {
                        const errormsg = result[0]['errormsg'];
                        toast.error(errormsg);
                        //   setErrorval(errormsg);
                        //  setsucc('')
                    }


                    //  console.log(result);
                },
                (error) => {
                    console.log('no');
                    console.log(error);
                    toast.error(error);
                }
            );
        return false;
    }
    if (careerlist.length > '0') {
        return (



            <Fragment>
                <Breadcrumb title="Career" parent="Career List" link="/career" subparent={"Career View"} />
                {/* <!-- Container-fluid starts--> */}
                <Container fluid={true}>
                    <Row>

                        <Col sm="12">
                            <Card >

                                <CardBody>
                                    <Box sx={{ width: '100%' }}>

                                        <form name="contactform" method='post' className="row contact-form" onSubmit={onsubmit}>
                                            {/* Form Select */}
                                            <div className="col-md-12 input-subject">
                                                <p style={{ color: 'red', textAlign: 'center', padding: '5px' }}>{Errorval}</p>
                                                <p style={{ color: 'green', textAlign: 'center', padding: '5px' }}>{sucval}</p>

                                            </div>
                                            <div className="col-md-6 input-subject">
                                                <h5 ><b>Full Name</b></h5>
                                                <input required value={cname}
                                                    type="text"
                                                    name="name"
                                                    className="form-control name"
                                                    placeholder="Your Name*"
                                                    onChange={(e) => { setcname(e.target.value) }}

                                                />

                                            </div>
                                            {/* Contact Form Input */}
                                            <div className="col-md-6 input-subject">
                                                <h5 ><b>Email Address</b></h5>

                                                <input required value={user_email}
                                                    type="text"
                                                    name="email"
                                                    className="form-control email"
                                                    placeholder="Email Address*"
                                                    onChange={(e) => { setuser_email(e.target.value) }}

                                                />
                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>
                                                <h5 ><b>Mobile No</b></h5>

                                                <input required value={user_mobile}
                                                    type="text"
                                                    name="text"
                                                    className="form-control "
                                                    placeholder="Mobile*"
                                                    onChange={(e) => { setuser_mobile(e.target.value) }}

                                                />
                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>
                                                <h5 ><b>Country</b></h5>

                                                <select className="form-control" required
                                                    aria-label="Default select example" onChange={(e) => { setcountryval(e.target.value) }} value={countryval} name="country">
                                                    <option>Select</option>
                                                    {

                                                        Countrylist.map((d, i) =>
                                                            <option value={d.countries_iso_code_2}>{d.countries_name}</option>
                                                        )}

                                                </select>

                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>
                                                <h5 ><b>State</b></h5>

                                                <input required value={state}
                                                    type="text"
                                                    name="state"
                                                    className="form-control "
                                                    placeholder="State*"
                                                    onChange={(e) => { setstate(e.target.value) }}

                                                />

                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>
                                                <h5 ><b>City</b></h5>

                                                <input required value={city}
                                                    type="text"
                                                    name="city"
                                                    className="form-control "
                                                    placeholder="City*"
                                                    onChange={(e) => { setcity(e.target.value) }}

                                                />

                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>
                                                <h5 ><b>Postcode</b></h5>
                                                <input required value={postcode}
                                                    type="text"
                                                    name="postcode"
                                                    className="form-control "
                                                    placeholder="postcode*"
                                                    onChange={(e) => { setpostcode(e.target.value) }}

                                                />

                                            </div>

                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>
                                                <h5 ><b> Address 1</b></h5>

                                                <input required value={add1}
                                                    type="text"
                                                    name="add1"
                                                    className="form-control "
                                                    placeholder="Address 1*"
                                                    onChange={(e) => { setadd1(e.target.value) }}

                                                />


                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>
                                                <h5 ><b> Address 2</b></h5>

                                                <input required value={add2}
                                                    type="text"
                                                    name="add2"
                                                    className="form-control "
                                                    placeholder="Address 2*"
                                                    onChange={(e) => { setadd2(e.target.value) }}

                                                />


                                            </div>
                                            <div className="col-md-12" style={{ paddingTop: '10px' }}>
                                                <h5 ><b> Resume</b></h5>

                                                <span style={{ fontSize: '13px' }}>(accepted extenstions pdf,doc,docx) </span>
                                                <input onChange={checkfileext} required
                                                    type="file"

                                                />
                                                <span style={{ color: 'red' }}><br />{fileerror}</span>
                                                <h5 style={{ color: 'blue' }}><a href={`${careerlist[0].file}`} target="_blank">Click here to download.</a></h5>
                                            </div>
                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>
                                                <h5 ><b> Description</b></h5>


                                                <textarea required value={desc}
                                                    className="form-control message"
                                                    name="message"
                                                    rows={6}
                                                    placeholder="Description..."
                                                    defaultValue={""}
                                                    onChange={(e) => { setdesc(e.target.value) }}

                                                />
                                            </div>


                                            {/* Contact Form Button */}
                                            <div className="col-md-12 mt-15 form-btn text-right">

                                                <button disabled={fileerror != '' ? true : false}
                                                    className="btn btn-primary btn-square bootstrap-touchspin-down"
                                                    type="submit"

                                                >
                                                    Update
                                                </button>

                                            </div>
                                            {/* Contact Form Message */}
                                            <div className="col-lg-12 contact-form-msg">
                                                <span className="loading" />
                                            </div>
                                        </form>

                                    </Box>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>







        );
    }
    else {
        return <>Loading..</>
    }
}
